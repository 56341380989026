const tabs = [
  {
    id: "profile",
    name: "Your Profile",
  },
  {
    id: "style-guide",
    name: "Brand Kit",
  },
];
export function ProfileTab({
  activeTab,
  onTabChange,
}: {
  activeTab: string;
  onTabChange: (tabId: string) => void;
}) {
  return (
    <div className="p-1 w-full md:w-auto rounded-md border border-gray-200 justify-start items-start gap-1 flex">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          onClick={() => onTabChange(tab.id)}
          className={`flex-1 md:flex-none px-4 py-3 rounded justify-center items-center gap-2.5 flex ${activeTab === tab.id ? "bg-white" : ""}`}
        >
          <span className={`${activeTab === tab.id ? "text-zinc-900 font-medium" : "text-gray-600 font-normal"} text-base  font-['Inter'] leading-tight`}>
            {tab.name}
          </span>
        </button>
      ))}
    </div>
  );
}
