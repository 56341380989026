import EmojiPicker from "@emoji-mart/react";
import { Button } from "../../../components/Button";
import { Modal } from "../../../components/Modal";
import { SelectInput } from "../../../components/SelectInput";
import { TagPeople } from "../../schedule/components/TagPeople";
import { PaymentView } from "../../schedule/components/PaymentView";
import { useUser } from "../../profile/context/user-context";
import { ReactComponent as ImageIcon } from "../../../assets/ic_image.svg";
import { ReactComponent as MagicWand } from "../../../assets/magic-wand.svg";
import { ReactComponent as EmojiIcon } from "../../../assets/ic_emoji.svg";
import Tippy from "@tippyjs/react";
import userImage from "../../../assets/user.png";
import { DateTimePicker } from "../../schedule/components/DateTimePicker";
import { useApp } from "../../../services/app-context";
import { LocationInput } from "../../schedule/components/LocationInput";
import VideoView from "../../onboarding/components/VideoView";

export function PaymentPopup({ open, onClose, isSubscriptionActive }) {
  const { userInfo } = useUser() as any;
  const { dashboard } = useApp() as any;
  const isPostReadOnly = true;
  const postForm = dashboard?.posts?.[0] || {};
  return (
    <Modal
      contentClassName="pb-20 md:pb-0 !px-0 lg:max-w-[940px] !mx-0"
      open={open}
      onClose={onClose}
      closeButtonStyle="right-8 top-8 lg:-right-8 lg:top-0 [&>svg_path]:stroke-black lg:[&>svg_path]:stroke-white"
    >
      <div className="overflow-y-auto max-h-[calc(100vh-50px)] [&>.payment-view]:!border-b-[0px]">
        {!isSubscriptionActive && <PaymentView />}

        {!isSubscriptionActive && (
          <>
            <div className="w-full flex justify-center">
              <div className="bg-white md:w-[400px] p-8 md:h-[320px] [&_svg]:!h-[138px] [&_svg]:!w-[138px] [&>div]:!w-full [&>div]:!h-full">
                <VideoView src="/media/payment-popup.mp4" />
              </div>
            </div>
            <h1 className="text-dark1 text-center text-sm font-medium leading-normal md:leading-relaxed mb-4">
              Dive into the full feature set - watch the demo video above!
            </h1>
          </>
        )}
      </div>
    </Modal>
  );
}
