import { Button } from "../../../components/Button";
import { Modal } from "../../../components/Modal";

export function ColorChangeConfirm({
  open,
  onClose,
  onConfirm,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) {
  return (
    <Modal
      contentClassName="!max-w-[500px]"
      open={open}
      onClose={onClose}
      showCloseButton={false}
      modalClassName="!overflow-y-hidden !md:overflow-y-auto"
    >
      <div className="text-center">
        <div className="text-center text-[#000] text-2xl font-semibold leading-[34px]">
          You're about to update your colors...
        </div>
        <div className="mt-3.5 leading-[18px] text-center text-black text-sm font-normal">
          Future content adapts to your new color choices. Our images use a
          palette with a primary brand color, secondary dark, secondary light,
          accent, and neutral. For more information,{" "}
          <a
            href="https://coaches.guidely.com/knowledge-base/more-info-on-color-palettes"
            target="_blank"
            rel="noreferrer"
            className="text-primary underline"
          >
            click here.
          </a>
        </div>
        <div className="flex gap-2.5 justify-center mt-6">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onConfirm} variant="primary">
            Yes, Update
          </Button>
        </div>
      </div>
    </Modal>
  );
}
