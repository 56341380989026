import React from "react";
import { SelectInput } from "../../../components/SelectInput";
import { TextInput } from "../../../components/TextInput";
import { ActionButtons } from "./ActionButtons";
import { api } from "../../../services/api";

export const getOptionsFromQuestions = (list: any, code: string) => {
  const question = list?.find((item: any) => item.code === code);
  return question?.options || [];
};

export function Step3({
  onBack,
  onNext,
  metaData,
  data,
}: {
  onBack: () => void;
  onNext: (data: any) => void;
  metaData: any;
  data: any;
}) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<any>({});

  const handleBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    onBack();
  };
  const handleNext = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (form.MONTHLY_CLIENTS && form.ABOUT_BUSINESS && form.COACHING_AGE) {
      setLoading(true);
      const response = await api.saveOnboardingSection(metaData.code, {
        answers: {
          ABOUT_BUSINESS: form.ABOUT_BUSINESS,
          COACHING_AGE: form.COACHING_AGE,
          MONTHLY_CLIENTS: form.MONTHLY_CLIENTS,
        },
      });
      setLoading(false);
      if (response.status === 200) {
        onNext({
          MONTHLY_CLIENTS: form.MONTHLY_CLIENTS,
          ABOUT_BUSINESS: form.ABOUT_BUSINESS,
          COACHING_AGE: form.COACHING_AGE,
        });
      }
    }
    // onNext();
  };

  React.useEffect(() => {
    if (data) {
      setForm(data);
    }
  }, [data]);

  const disabled =
    !form.MONTHLY_CLIENTS || !form.ABOUT_BUSINESS || !form.COACHING_AGE;
  return (
    <div className="w-full overflow-auto max-h-[calc(100vh-125px)] py-2">
      <h1 className="text-dark1 text-lg  md:text-2xl font-semibold leading-normal md:leading-loose">
        Next, please share some information about your business
      </h1>
      <p className="text-dark2 text-sm leading-normal mb-5 mt-1.5">
        Which of the following best describe your business as it currently is?
      </p>
      <div className="flex-1 flex gap-4 flex-col">
        <SelectInput
          placeholder="My business is…"
          data={getOptionsFromQuestions(metaData.questions, "ABOUT_BUSINESS")}
          onChange={(e) =>
            setForm((prev) => ({ ...prev, ABOUT_BUSINESS: e.target.value }))
          }
          value={form.ABOUT_BUSINESS || ""}
          className="!bg-[99%_55%]"
        />
        <SelectInput
          data={getOptionsFromQuestions(metaData.questions, "COACHING_AGE")}
          onChange={(e) =>
            setForm((prev) => ({ ...prev, COACHING_AGE: e.target.value }))
          }
          value={form.COACHING_AGE || ""}
          placeholder="I’ve been practicing coaching for…"
          className="!bg-[99%_55%]"
        />
        <SelectInput
          onChange={(e) =>
            setForm((prev) => ({ ...prev, MONTHLY_CLIENTS: e.target.value }))
          }
          value={form.MONTHLY_CLIENTS || ""}
          data={getOptionsFromQuestions(metaData.questions, "MONTHLY_CLIENTS")}
          placeholder="My monthly client roster is…"
          className="!bg-[99%_55%]"
        />
      </div>
      <div className="my-8 border-t border-lightGray"></div>
      <ActionButtons
        onBack={handleBack}
        onNext={handleNext}
        disableNext={disabled}
        loading={loading}
      />
    </div>
  );
}
