import { useRef, useState } from "react";
import { ReactComponent as PlayButtonIcon } from "../../../assets/play-button.svg";
import React from "react";

function VideoView({ src = "/media/demo-video.mp4" }: { src?: string }) {
  const [play, setPlay] = useState(false);
  const playerRef = useRef<HTMLVideoElement>(null);

  const handlePlay = (e: any) => {
    e.stopPropagation();
    setPlay(true);
    playerRef.current?.play();
  };

  const handleVideoClick = (e: any) => {
    e.stopPropagation();
    // if video is playing, pause it
    if (play) {
      setPlay(false);
      playerRef.current?.pause();
    }
  };

  return (
    <div className="relative shadow-[0px_0px_18px_0px_#0000001A] border-[9px] border-[#FFFFFF33] rounded-lg">
      <video
        src={src}
        ref={playerRef}
        className="w-full h-full object-cover"
        controls={false}
        autoPlay={play}
        loop={true}
        playsInline={true}
        onClick={handleVideoClick}
      />
      {!play && (
        <button
          onClick={handlePlay}
          className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center"
        >
          <PlayButtonIcon />
        </button>
      )}
    </div>
  );
}

export default React.memo(VideoView);
