import { ActionButtons } from "./ActionButtons";
import { ReactComponent as CameraIcon } from "../../../assets/camera.svg";
import { ReactComponent as CloseIcon } from "../../../assets/x.svg";

import React, { useEffect } from "react";
import { api } from "../../../services/api";
import { getUser } from "../../../services/utils";
import { fileUpload } from "../../../services/file-upload";
import { ShowToast } from "../../../services/toast";
import { Step10 } from "./Step10";
import { retry } from "../../../services/retry";
import TagManager from "react-gtm-module";
import { GTM_ID } from "../../../constant";

function FileInput(props: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      {...props}
      className="w-24 h-24 bg-neutral-100 rounded-lg flex justify-center items-center"
    >
      <div className="w-14 h-14 bg-white rounded-full border border-gray-200 flex justify-center items-center">
        <CameraIcon className="w-6 h-6" />
      </div>
    </button>
  );
}

export function Step8({
  onBack,
  onNext,
  metaData,
  data,
  setGeneratingPrompt,
  generatingPrompt,
}: {
  onBack: () => void;
  onNext: (data: any) => void;
  metaData: any;
  data: any;
  setGeneratingPrompt: (value: boolean) => void;
  generatingPrompt: boolean;
}) {
  const user = getUser();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [promptLoading, setPromptLoading] = React.useState<boolean>(false);
  const [images, setImages] = React.useState<File[]>([]);
  const uploadRef = React.useRef<HTMLInputElement>(null);
  const handleBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    onBack();
  };
  const handleNext = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      if (images.length > 0) {
        setLoading(true);
        // upload all images
        const imageList: string[] = [];
        for (let i = 0; i < images.length; i++) {
          const image = images[i];
          const uploadResponse = await fileUpload(
            image,
            user._id,
            "USER_IMAGE"
          );
          // images[i] = uploadResponse.data;
          if (uploadResponse.url) {
            imageList.push(uploadResponse.url);
          }
        }
        const response = await api.saveOnboardingSection(metaData.code, {
          answers: {
            IMAGES: imageList,
          },
        });
        setLoading(false);
        if (response.status === 200) {
          // generate step 9 prompt
          setGeneratingPrompt(true);
          setPromptLoading(true);
          const response = await retry(
            () =>
              api.saveOnboardingSection("BRAND_VOICE", {
                answers: {
                  BRAND_VOICE: [],
                },
              }),
            () => {
              // code if any when retrying
            },
            3
          );
          // wait for 2.2 minutes
          setTimeout(() => {
            setPromptLoading(false);
          }, 1000 * 60 * 2);
          // setGeneratingPrompt(false);
          if (response.status === 200) {
            // Call GTM dataLayer

            const tagManagerArgs = {
              gtmId: GTM_ID,
              dataLayer: {
                event: "onboardingCompleted",
                userId: user._id,
                email: user.email,
              },
            };
            if (TagManager.dataLayer) {
              TagManager.dataLayer(tagManagerArgs);
            }
          }
          // onNext({
          //   IMAGES: images,
          // });
        }
      }
    } catch (err) {
      ShowToast({ message: "Failed to save answers", type: "error" });
      setLoading(false);
      setGeneratingPrompt(false);
      setPromptLoading(false);
    }
    // else {
    //   setLoading(true);
    //   const response = await api.saveOnboardingSection(metaData.code, {
    //     answers: {
    //       IMAGES: [],
    //     },
    //   });
    //   setLoading(false);
    //   onNext({
    //     IMAGES: [],
    //   });
    // }
  };
  const handleRemove = (index: number) => {
    setImages((prev) => prev.filter((_, i) => i !== index));
  };
  const urls = Array.from(images).map((file) => URL.createObjectURL(file));

  useEffect(() => {
    if (
      data &&
      data.IMAGES &&
      data.IMAGES[0] &&
      typeof data.IMAGES[0] !== "string"
    ) {
      setImages(data.IMAGES || []);
    }
  }, [data]);

  const disabled = images.length < 1;

  if (generatingPrompt) {
    return (
      <Step10
        name={data.FIRST_NAME}
        showLoading={promptLoading}
        onClick={() => {
          // not to update the state
          onNext({
            BRAND_VOICE: [],
            IMAGES: images,
          });
        }}
      />
    );
  }

  return (
    <div className="w-full overflow-auto max-h-[calc(100vh-125px)] py-2">
      <h1 className="text-dark1 text-lg  md:text-2xl font-semibold leading-normal md:leading-loose">
        Showcase your authentic you
      </h1>
      <p className="text-dark2 text-sm leading-normal mb-5 mt-1.5">
        Please upload up to 10 clear, high-quality photos of yourself so we can
        create your custom social media imagery.
      </p>
      <div className="flex-1 flex gap-4 flex-wrap">
        {/** invisible input type file with div to upload file */}
        <input
          type="file"
          className="hidden"
          ref={uploadRef}
          multiple
          accept="image/*"
          onChange={(e) => {
            const files = e.target.files;
            if (files?.length) {
              setImages([...images, ...Array.from(files)]);
            }
            e.target.value = "";
          }}
        />
        {urls.map((image, index) => (
          <div
            key={image}
            className="w-24 h-24 relative bg-neutral-100 rounded-lg flex justify-center items-center"
          >
            <button
              onClick={() => handleRemove(index)}
              className="transition duration-75 hover:scale-110 absolute bg-primary rounded-full -top-2 -right-2 p-1"
              disabled={loading}
            >
              <CloseIcon className="w-4 h-4" />
            </button>
            <img
              src={image}
              alt="media"
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
        ))}
        {images.length < 10 && (
          <FileInput
            onClick={() => uploadRef.current?.click()}
            disabled={loading}
          />
        )}
      </div>
      <div className="my-8 border-t border-lightGray"></div>
      <ActionButtons
        onBack={handleBack}
        onNext={handleNext}
        disableNext={loading || disabled}
        loading={loading}
      />
    </div>
  );
}
