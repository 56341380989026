import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { ProfileTab } from "./components/ProfileTab";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { useEffect, useState } from "react";
import { Profile } from "./Profile";
import { BrandStyleGuide } from "./BrandStyleGuide";
import { getUser } from "../../services/utils";
import { api } from "../../services/api";
import { Button } from "../../components/Button";
import { EditOnboarding } from "./components/EditOnBoarding";
import React from "react";
import { pageView } from "../../services/mixpanel";
import { useApp } from "../../services/app-context";
import { PaymentPopup } from "../dashboard/components/PaymentPopup";

export function ProfileWrapper() {
  const { instagramConnected } = useOutletContext<{
    instagramConnected: boolean;
    fetchInstagramConnected: (userId: string) => void;
  }>();
  const [showOnBoarding, setShowOnBoarding] = useState<boolean>(false);
  const [profile, setProfile] = useState<any>({});
  const [fetching, setFetching] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("profile");
  const [sectionData, setSectionData] = useState<any>({});
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const app = useApp() as any;
  const navigate = useNavigate();

  const user = getUser();

  const fetchProfile = React.useCallback(() => {
    setFetching(true);
    api.getUserProfile(user._id).then((response) => {
      setProfile(response);
      setFetching(false);
    });
  }, [user._id]);

  useEffect(() => {
    // call api to get profile data, get id from localstorage
    fetchProfile();
  }, [fetchProfile]);

  useEffect(() => {
    api.getOnboardingSections().then((res) => {
      setSectionData(res);
    });
  }, []);

  useEffect(() => {
    pageView();
  }, []);

  return (
    <div className="relative bg-ultraLightGray p-4 md:p-6">
      <div className="flex justify-center gap-3.5 md:gap-0 md:justify-between items-center mb-4 flex-wrap">
        <ProfileTab activeTab={activeTab} onTabChange={setActiveTab} />

        {/* {activeTab === "profile" && (
          <Link
            to="/profile/edit"
            className="w-full md:w-auto px-5 py-3 bg-white rounded-md border border-primary justify-center items-center gap-1.5 flex"
          >
            <EditIcon />
            <div className="text-black text-sm font-medium leading-normal">
              Edit profile
            </div>
          </Link>
        )} */}
        {activeTab === "profile" && (
          <Button
            variant="outline"
            className="w-full md:w-auto px-5 py-3 bg-white rounded-md border border-primary justify-center items-center gap-1.5 flex"
            onClick={() => {
              !app.isSubscriptionActive
                ? setShowPopup(true)
                : setShowOnBoarding(true);
            }}
          >
            <EditIcon />
            <div className="text-black text-sm font-medium leading-normal">
              Edit profile
            </div>
          </Button>
        )}
      </div>
      {activeTab === "profile" && (
        <Profile
          profile={profile}
          fetching={fetching}
          setProfile={setProfile}
          fetchProfile={fetchProfile}
        />
      )}
      {activeTab === "style-guide" && (
        <BrandStyleGuide profile={profile} id={user._id} />
      )}
      {showOnBoarding && (
        <EditOnboarding
          onClose={(flag?: boolean) => {
            if (flag) {
              fetchProfile();
            }
            setShowOnBoarding(false);
          }}
          sectionData={sectionData}
          onboardingSubmissions={profile?.onboardingSubmissions}
          instagramConnected={instagramConnected}
        />
      )}
      {showPopup && (
        <PaymentPopup
          isSubscriptionActive={false}
          open={showPopup}
          onClose={() => setShowPopup(false)}
        />
      )}
    </div>
  );
}
