import { Button } from "../../components/Button";
import { TextArea } from "../../components/TextArea";
import { TextInput } from "../../components/TextInput";
import { ReactComponent as Check } from "../../assets/check.svg";
import { ReactComponent as Back } from "../../assets/back.svg";
import { Link, useNavigate } from "react-router-dom";
import { EditConfirm } from "./EditConfirm";
import React, { useEffect } from "react";
import { getUser } from "../../services/utils";
import { api } from "../../services/api";
import { ReactComponent as CloseIcon } from "../../assets/x.svg";
import { fileUpload } from "../../services/file-upload";
import { Loading } from "../../components/Loading/Loading";
import { LoadingScreen } from "./LoadingScreen";
import { ShowToast } from "../../services/toast";
import { useUser } from "./context/user-context";
import userImage from "../../assets/user.png";

function FileInput(props: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      {...props}
      className="w-[57px] h-[57px] bg-[#F6F6F6] rounded-lg flex justify-center items-center"
    >
      <div className="text-3xl flex justify-center items-center">+</div>
    </button>
  );
}

const PROFILE_PIC =
  "https://scontent.fstv5-1.fna.fbcdn.net/v/t51.2885-15/393470545_363262946050114_2699725582897640060_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=7d201b&_nc_ohc=QnvOCbmlI1cAX-CBKHA&_nc_ht=scontent.fstv5-1.fna&edm=AL-3X8kEAAAA&oh=00_AfCJRu0jxavCkUKHt15MrG7gOlBxmY2yn1JIT-6gkSwJkQ&oe=653AE3BE";

export function EditProfile() {
  const user = getUser();
  const navigate = useNavigate();
  const { userInfo, fetchUser } = useUser() as any;
  const [profilePicLoading, setProfilePicLoading] =
    React.useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [profileForm, setProfileForm] = React.useState<any>({});
  const [newImages, setNewImages] = React.useState<File[]>([]);
  const uploadRef = React.useRef<HTMLInputElement>(null);
  const profilePicRef = React.useRef<HTMLInputElement>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [fetching, setFetching] = React.useState<boolean>(false);

  const handleSave = async () => {
    // upload all images
    const imageList: string[] = [];
    setLoading(true);
    try {
      for (let i = 0; i < newImages.length; i++) {
        const image = newImages[i];
        const uploadResponse = await fileUpload(image, user._id, "USER_IMAGE");
        // images[i] = uploadResponse.data;
        if (uploadResponse.url) {
          imageList.push(uploadResponse.url);
        }
      }
      // prepare object to send to api
      const submissions = { ...profileForm };
      delete submissions.profilePic;
      delete submissions.email;
      delete submissions.joinedDate;
      delete submissions.clientNiche;
      delete submissions.speciality;

      const data = {
        onboardingSubmissions: {
          ...submissions,
          IMAGES: [...profileForm.IMAGES, ...imageList],
        },
        profilePic: profileForm.profilePic,
        clientNiche: profileForm.clientNiche,
        speciality: profileForm.speciality,
      };
      const response = await api.saveUserProfile(user._id, data);
      if (response.status === 200) {
        ShowToast({
          type: "success",
          message: "Your Profile has been updated",
        });
        fetchUser(user._id);
        navigate("/profile");
      }
      setLoading(false);
    } catch (error) {
      ShowToast({ type: "error", message: "Something went wrong" });
      setLoading(false);
    }
  };

  const handleConfirm = () => {
    setOpenConfirmModal(false);
    handleSave();
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setProfileForm((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleBrandVoiceChange = (e: any, id: string) => {
    const { name, value } = e.target;
    setProfileForm((prev: any) => ({
      ...prev,
      BRAND_VOICE: prev.BRAND_VOICE.map((item: any) => {
        if (item.id === id) {
          return {
            ...item,
            [name]: value,
          };
        }
        return item;
      }),
    }));
  };

  const handleProfileRemove = () => {
    setProfileForm((prev: any) => ({
      ...prev,
      profilePic: "",
    }));
  };

  const handleRemove = (image: string) => {
    setProfileForm((prev: any) => ({
      ...prev,
      IMAGES: prev.IMAGES.filter((item: any) => item !== image),
    }));
  };

  const handleProfilePicChange = async (file: File) => {
    setProfilePicLoading(true);
    const uploadResponse = await fileUpload(file, user._id, "USER_THUMBNAIL");
    if (uploadResponse.url) {
      setProfileForm((prev: any) => ({
        ...prev,
        profilePic: uploadResponse.url,
      }));
    }
    setProfilePicLoading(false);
  };

  const handleNewImageRemove = (index: number) => {
    setNewImages((prev) => prev.filter((_, i) => i !== index));
  };

  useEffect(() => {
    // call api to get profile data, get id from localstorage
    setFetching(true);
    api.getUserProfile(user._id).then((response) => {
      setProfileForm({
        email: response.email,
        joinedDate: response.joinedDate,
        profilePic: response.profilePic,
        clientNiche: response.clientNiche,
        speciality: response.speciality,
        ...response.onboardingSubmissions,
      });
      setFetching(false);
    });
  }, [user._id]);
  const urls = newImages.map((file) => URL.createObjectURL(file));
  return (
    <div className="relative flex-col bg-ultraLightGray flex">
      {fetching && <LoadingScreen />}
      <div className="flex-col gap-3 flex px-6 my-5">
        <Link
          to="/profile"
          className="justify-start items-center gap-1 inline-flex"
        >
          <Back className="[&>path]:stroke-primary" />
          <div className="text-zinc-900 text-sm font-normal font-['Inter']">
            Back
          </div>
        </Link>
      </div>
      <div className="flex flex-col px-4 md:px-6 gap-3 md:gap-5 max-h-[calc(100vh_-_202px)] overflow-auto pb-1">
        <div className="text-neutral-800 text-4xl font-semibold font-['Inter'] leading-10">
          Edit profile
        </div>
        <div className="self-stretch grow shrink basis-0 p-5 md:p-8 bg-white rounded-lg flex-col justify-start items-start gap-6 flex">
          <div className="text-dark1 text-2xl font-bold leading-tight">
            General
          </div>
          <div className="md:items-center gap-3 md:gap-5 flex md:flex-row flex-col">
            <span className="w-36 text-dark1 text-sm font-medium">Avatar</span>
            <div className="md:items-center gap-6 flex md:flex-row flex-col">
              <div className="justify-start items-center gap-3 flex">
                <input
                  type="file"
                  className="hidden"
                  ref={profilePicRef}
                  accept="image/*"
                  onChange={(e) => {
                    const files = e.target.files;
                    if (files?.length) {
                      handleProfilePicChange(files[0]);
                    }
                    e.target.value = "";
                  }}
                />
                <div className="w-[72px] h-[72px] md:w-28 md:h-28 bg-white overflow-hidden relative rounded-full flex-col justify-start items-start flex">
                  <img
                    src={
                      userInfo.instagramProfilePic ||
                      userInfo?.IMAGES?.[0] ||
                      userImage
                    }
                    alt="profile-pic"
                    className="w-full h-full object-cover rounded-full"
                  />
                  {profilePicLoading && (
                    <div className="absolute top-0 bottom-0 left-0 right-0 bg-black/30 flex justify-center items-center [&>svg]:mr-0 [&>svg]:w-6 [&>svg]:h-6">
                      <Loading />
                    </div>
                  )}
                </div>
                {/* <Button
                  className="!px-4 !py-2"
                  variant="outline"
                  onClick={() => profilePicRef.current?.click()}
                >
                  Change
                </Button>

                <Button
                  className="!px-4 !py-2"
                  variant="outline"
                  onClick={() =>
                    ShowToast({ type: "error", message: "Not implemented yet" })
                  }
                >
                  Remove
                </Button> */}
              </div>
            </div>
          </div>
          <div className="md:items-center gap-3 md:gap-5 flex w-full flex-wrap md:flex-row flex-col">
            <div className="w-36 text-dark1 text-sm font-medium">Full Name</div>
            <div className="gap-4 flex flex-1">
              <TextInput
                className="p-[15px]"
                placeholder="First name"
                value={profileForm.FIRST_NAME}
                name="FIRST_NAME"
                onChange={handleChange}
                maxLength={100}
              />
              <TextInput
                className="p-[15px]"
                placeholder="Last name"
                value={profileForm.LAST_NAME}
                name="LAST_NAME"
                onChange={handleChange}
                maxLength={100}
              />
            </div>
          </div>
          <div className="md:items-center gap-3 md:gap-5 flex w-full md:flex-row flex-col">
            <div className="w-36 text-dark1 text-sm font-medium">Instagram</div>
            <div className="gap-4 flex flex-1">
              <TextInput
                className="p-[15px]"
                placeholder="Instagram id"
                value={`@${
                  typeof profileForm?.INSTAGRAM_HANDLE === "string"
                    ? profileForm?.INSTAGRAM_HANDLE
                    : profileForm?.INSTAGRAM_HANDLE?.username
                }`}
                disabled
              />
            </div>
          </div>
          <div className="md:items-center gap-3 md:gap-5 flex w-full md:flex-row flex-col">
            <div className="w-36 text-dark1 text-sm font-medium">
              Client Niche
            </div>
            <div className="gap-4 flex flex-1">
              <TextArea
                name="clientNiche"
                value={profileForm.clientNiche}
                onChange={handleChange}
                disabled
              />
            </div>
          </div>
          <div className="justify-start md:items-center gap-5 flex md:flex-row flex-col">
            <div className="w-36 text-dark1 text-sm font-medium">
              Uploaded Pictures
            </div>
            <div className="justify-start items-start gap-2 flex flex-wrap">
              <input
                type="file"
                className="hidden"
                ref={uploadRef}
                multiple
                accept="image/*"
                onChange={(e) => {
                  const files = e.target.files;
                  if (files?.length) {
                    setNewImages([...newImages, ...Array.from(files)]);
                  }
                  e.target.value = "";
                }}
              />
              {
                // @ts-ignore
                profileForm.IMAGES?.map((image, index) => (
                  <div
                    key={index}
                    className="w-[57px] h-[57px] relative bg-neutral-100 rounded-lg flex justify-center items-center"
                  >
                    <button
                      onClick={() => handleRemove(image)}
                      className="transition duration-75 hover:scale-110 absolute bg-primary rounded-full -top-2 -right-2 p-1"
                      disabled={false}
                    >
                      <CloseIcon className="w-2 h-2" />
                    </button>
                    <img
                      src={image}
                      alt="media"
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </div>
                ))
              }
              {urls.map((image, index) => (
                <div
                  key={index}
                  className="w-[57px] h-[57px] relative bg-neutral-100 rounded-lg flex justify-center items-center"
                >
                  <button
                    onClick={() => handleNewImageRemove(index)}
                    className="transition duration-75 hover:scale-110 absolute bg-primary rounded-full -top-2 -right-2 p-1"
                    disabled={false}
                  >
                    <CloseIcon className="w-2 h-2" />
                  </button>
                  <img
                    src={image}
                    alt="media"
                    className="w-full h-full object-cover rounded-lg"
                  />
                </div>
              ))}
              <FileInput
                onClick={() => uploadRef.current?.click()}
                disabled={false}
              />
            </div>
          </div>
        </div>
        <div className="self-stretch grow shrink basis-0 p-5 md:p-8 bg-white rounded-lg flex-col justify-start items-start gap-6 flex">
          <div className="text-dark1 text-2xl font-bold leading-tight">
            Offering
          </div>
          <div className="md:items-center gap-3 md:gap-5 flex w-full md:flex-row flex-col">
            <div className="w-36 text-dark1 text-sm font-medium">Name</div>
            <div className="gap-4 flex flex-1">
              <TextInput
                className="p-[15px]"
                placeholder="Name"
                value={profileForm.OFFERING_NAME}
                name="OFFERING_NAME"
                onChange={handleChange}
                maxLength={200}
                disabled
              />
            </div>
          </div>
          <div className="md:items-center gap-3 md:gap-5 flex w-full md:flex-row flex-col">
            <div className="w-36 text-dark1 text-sm font-medium">Format</div>
            <div className="gap-4 flex flex-1">
              <TextArea
                value={profileForm.OFFERING_FORMAT}
                onChange={handleChange}
                name="OFFERING_FORMAT"
                maxLength={500}
                disabled
              />
            </div>
          </div>
          <div className="md:items-center gap-3 md:gap-5 flex w-full md:flex-row flex-col">
            <div className="w-36 text-dark1 text-sm font-medium">Pricing</div>
            <div className="gap-4 flex flex-1">
              <TextInput
                className="p-[15px]"
                placeholder="Price"
                value={profileForm.OFFERING_PRICING}
                onChange={handleChange}
                name="OFFERING_PRICING"
                maxLength={200}
                disabled
              />
            </div>
          </div>
          <div className="md:items-center gap-3 md:gap-5 flex w-full md:flex-row flex-col">
            <div className="w-36 text-dark1 text-sm font-medium">Duration</div>
            <div className="gap-4 flex flex-1">
              <TextInput
                className="p-[15px]"
                placeholder="Instagram id"
                value={profileForm.OFFERING_DURATION}
                onChange={handleChange}
                name="OFFERING_DURATION"
                maxLength={200}
                disabled
              />
            </div>
          </div>
          <div className="md:items-center gap-3 md:gap-5 flex w-full md:flex-row flex-col">
            <div className="w-36 text-dark1 text-sm font-medium">
              Tools/Outcome
            </div>
            <div className="gap-4 flex flex-1">
              <TextArea
                value={profileForm.OFFERING_OUTCOME}
                onChange={handleChange}
                name="OFFERING_OUTCOME"
                maxLength={500}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="self-stretch grow shrink basis-0 p-5 md:p-8 bg-white rounded-lg flex-col justify-start items-start gap-6 flex">
          <div className="text-dark1 text-2xl font-bold leading-tight">
            Specialty/Gifts
          </div>
          <div className="md:items-center gap-3 md:gap-5 flex w-full md:flex-row flex-col">
            <div className="w-36 text-dark1 text-sm font-medium">
              Specialty/Gifts
            </div>
            <div className="gap-4 flex flex-1">
              <TextArea
                name="speciality"
                value={profileForm.speciality}
                onChange={handleChange}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="self-stretch grow shrink basis-0 p-5 md:p-8 bg-white rounded-lg flex-col justify-start items-start gap-6 flex">
          <div className="text-dark1 text-2xl font-bold leading-tight">
            Brand Voice
          </div>
          {profileForm.BRAND_VOICE?.map((item: any, index: number) => (
            <div
              key={item.id}
              className="md:items-center gap-3 md:gap-5 flex w-full md:flex-row flex-col"
            >
              <div className="w-36 text-dark1 text-sm font-medium">
                Example #{index + 1}
              </div>
              <div className="gap-4 flex flex-1">
                <TextArea
                  rows={3}
                  value={item.caption}
                  name="caption"
                  onChange={(e: any) => handleBrandVoiceChange(e, item.id)}
                  disabled
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="pl-3 pr-6 py-3 bg-white shadow">
        <div className="flex gap-2.5 justify-end">
          <Link to="/profile">
            <Button variant="outline">Cancel</Button>
          </Link>
          <Button
            variant="primary"
            loading={loading}
            onClick={() => setOpenConfirmModal(true)}
          >
            <Check className="[&>path]:stroke-black" width={17} height={17} />
            <span>Save</span>
          </Button>
        </div>
      </div>
      {openConfirmModal && (
        <EditConfirm
          open={openConfirmModal}
          onClose={() => setOpenConfirmModal(false)}
          onConfirm={handleConfirm}
        />
      )}
    </div>
  );
}
