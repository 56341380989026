import React, { useEffect } from "react";
import { SelectInput } from "../../../components/SelectInput";
import { TextInput } from "../../../components/TextInput";
import { ActionButtons } from "./ActionButtons";
import { api } from "../../../services/api";

export function Step5({
  onBack,
  onNext,
  metaData,
  data,
}: {
  onBack: () => void;
  onNext: (data: any) => void;
  metaData: any;
  data: any;
}) {
  const [form, setForm] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    onBack();
  };
  const handleNext = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (
      form.OFFERING_NAME &&
      form.OFFERING_FORMAT &&
      form.OFFERING_PRICING &&
      form.OFFERING_DURATION &&
      form.OFFERING_OUTCOME
    ) {
      setLoading(true);
      const response = await api.saveOnboardingSection(metaData.code, {
        answers: {
          OFFERING_NAME: form.OFFERING_NAME,
          OFFERING_FORMAT: form.OFFERING_FORMAT,
          OFFERING_PRICING: form.OFFERING_PRICING,
          OFFERING_DURATION: form.OFFERING_DURATION,
          OFFERING_OUTCOME: form.OFFERING_OUTCOME,
        },
      });
      setLoading(false);
      if (response.status === 200) {
        onNext({
          OFFERING_NAME: form.OFFERING_NAME,
          OFFERING_FORMAT: form.OFFERING_FORMAT,
          OFFERING_PRICING: form.OFFERING_PRICING,
          OFFERING_DURATION: form.OFFERING_DURATION,
          OFFERING_OUTCOME: form.OFFERING_OUTCOME,
        });
      }
    }
  };

  useEffect(() => {
    if (data) {
      setForm(data);
    }
  }, [data]);

  const disabled =
    !form.OFFERING_NAME ||
    !form.OFFERING_FORMAT ||
    !form.OFFERING_PRICING ||
    !form.OFFERING_DURATION ||
    !form.OFFERING_OUTCOME;
  return (
    <div className="w-full overflow-auto max-h-[calc(100vh-125px)] pt-2 pb-16 md:py-2">
      <h1 className="text-dark1 text-lg  md:text-2xl font-semibold leading-normal md:leading-loose">
        Now let’s delve into the heart of your coaching service
      </h1>
      <p className="text-dark2 text-sm leading-normal mb-5 mt-1.5">
        Describe the primary offering or package you’re keen on spotlighting.
        Remember, this isn’t set in stone and can be changed at any time!
      </p>
      <div className="flex-1 flex gap-4 flex-col">
        <TextInput
          onChange={(e) =>
            setForm((prev) => ({ ...prev, OFFERING_NAME: e.target.value }))
          }
          value={form.OFFERING_NAME}
          disabled={loading}
          placeholder="Name (e.g. The Balanced Boss, Holistic Life Coaching)"
        />
        <TextInput
          onChange={(e) =>
            setForm((prev) => ({ ...prev, OFFERING_FORMAT: e.target.value }))
          }
          value={form.OFFERING_FORMAT}
          disabled={loading}
          placeholder="Format (e.g. 45-minute one-on-one sessions)"
        />
        <TextInput
          onChange={(e) =>
            setForm((prev) => ({ ...prev, OFFERING_PRICING: e.target.value }))
          }
          value={form.OFFERING_PRICING}
          disabled={loading}
          placeholder="Pricing (e.g. $150 per session, $497 total cost)"
        />
        <TextInput
          onChange={(e) =>
            setForm((prev) => ({ ...prev, OFFERING_DURATION: e.target.value }))
          }
          value={form.OFFERING_DURATION}
          disabled={loading}
          placeholder="Duration (e.g. 5 sessions package, 3 months, weekly)"
        />
        <TextInput
          onChange={(e) =>
            setForm((prev) => ({ ...prev, OFFERING_OUTCOME: e.target.value }))
          }
          value={form.OFFERING_OUTCOME}
          placeholder="Tools/Outcome (e.g. Holistic well-being approaches)"
          disabled={loading}
        />
      </div>
      <div className="my-8 border-t border-lightGray"></div>
      <ActionButtons
        onBack={handleBack}
        onNext={handleNext}
        disableNext={disabled}
        loading={loading}
      />
    </div>
  );
}
