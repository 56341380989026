import React, { useEffect } from "react";
import "./App.css";
import Routes from "./pages/routes";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_OAUTH_CLIENT_ID, PRODUCTION_HOST } from "./constant";
import { Toaster } from "react-hot-toast";
import { pageView } from "./services/mixpanel";
import { gaPageView } from "./services/ga";
import { AnalyticsListener } from "./services/analytics-listener";
import { getUser } from "./services/utils";
import { api } from "./services/api";
import { AppProvider } from "./services/app-context";

function App() {
  const user = getUser();
  useEffect(() => {
    pageView();
    if (window.location.hostname === PRODUCTION_HOST) {
      gaPageView();
    }
  }, []);

  const makeLastActive = async (userId: string) => {
    try {
      await api.saveLastActive(userId);
    } catch (error) {}
  };

  useEffect(() => {
    try {
      if (user?._id) {
        makeLastActive(user?._id);
      }
    } catch (error) {}
    return () => {
      try {
        if (user?._id) {
          makeLastActive(user?._id);
        }
      } catch (error) {}
    };
  }, [user?._id]);

  return (
    <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
      <Toaster />
      <AppProvider>
        <AnalyticsListener>
          <div className="min-h-screen flex flex-col">
            <Routes />
          </div>
        </AnalyticsListener>
      </AppProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
