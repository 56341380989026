import React, { useEffect } from "react";
import { SelectInput } from "../../../components/SelectInput";
import { TextArea } from "../../../components/TextArea";
import { TextInput } from "../../../components/TextInput";
import { api } from "../../../services/api";
import { ActionButtons } from "./ActionButtons";
import { ShowToast } from "../../../services/toast";

export function Step6({
  onBack,
  onNext,
  data,
  metaData,
  generatePrompt,
  id,
  controllerRef,
}: {
  onBack: () => void;
  onNext: (data: any) => void;
  data?: any;
  metaData: any;
  generatePrompt?: boolean;
  id?: string;
  controllerRef?: any;
}) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<any>({});
  const handleBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    onBack();
  };
  const handleNext = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      if (form.DESCRIPTION) {
        setLoading(true);
        const response = await api.saveOnboardingSection(metaData.code, {
          answers: {
            DESCRIPTION: form.DESCRIPTION,
          },
        });
        setLoading(false);
        if (response.status === 200) {
          if (generatePrompt && id) {
            setLoading(true);
            if (controllerRef) {
              controllerRef.current = new AbortController();
            }
            const response = await api.generatePrompt(
              id,
              {
                promptType: "EDIT_PROFILE",
              },
              controllerRef?.current?.signal
            );
            setLoading(false);
          }
          onNext({
            DESCRIPTION: form.DESCRIPTION,
          });
        }
      }
    } catch (error: any) {
      setLoading(false);
      ShowToast({
        message:
          error?.code === "ERR_CANCELED"
            ? "Request Canceled"
            : "Something went wrong",
        type: "error",
      });
    }
  };

  useEffect(() => {
    return () => {
      if (controllerRef) {
        controllerRef.current && controllerRef.current.abort();
      }
    };
  }, [controllerRef]);

  React.useEffect(() => {
    if (data) {
      setForm(data);
    }
  }, [data]);

  const disabled = !form.DESCRIPTION || form.DESCRIPTION?.length < 200;
  return (
    <div className="w-full overflow-auto max-h-[calc(100vh-125px)] py-2">
      <h1 className="text-dark1 text-lg  md:text-2xl font-semibold leading-normal md:leading-loose">
        Wow, {data.FIRST_NAME}! I'd love to hear more about your journey as a
        coach
      </h1>
      <p className="text-dark2 text-sm leading-normal mb-5 mt-1.5">
        Please share the pivotal moment or experience that led you to want to
        share your gifts. Be as descriptive as possible! Please enter at least
        200 characters.
      </p>
      <div className="flex-1 flex flex-col">
        <TextArea
          placeholder="Share your unique story in detail"
          onChange={(e) => {
            // only allow 300 characters
            const value = e.target.value;
            setForm((prev) => ({ ...prev, DESCRIPTION: value }));
          }}
          name="DESCRIPTION"
          value={form.DESCRIPTION}
          disabled={loading}
        />
        <div className="flex justify-end">
          <span className="text-xs text-dark3">
            200 character min.
            {/* {form.DESCRIPTION?.length || 0}{" "}
            {form.DESCRIPTION?.length > 200 ? "" : "/ 200"} */}
          </span>
        </div>
      </div>
      <div className="my-8 border-t border-lightGray"></div>
      <ActionButtons
        onBack={handleBack}
        onNext={handleNext}
        disableNext={disabled}
        loading={loading}
      />
    </div>
  );
}
