export function TextArea({
  ...props
}: React.TextareaHTMLAttributes<HTMLTextAreaElement>) {
  return (
    <div className="w-full">
      <textarea
        {...props}
        className={`!outline-none w-full bg-ultraLightGray focus:bg-white rounded-lg border border-lightGray focus:border-grey2 p-5 text-base text-black ${
          props.className || ""
        }`}
      />
    </div>
  );
}
