import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import { TextInput } from "../../../components/TextInput";
import { ActionButtons } from "./ActionButtons";
import instagramAPI from "../../../services/instagram";
import React, { useCallback, useEffect, useRef } from "react";
import { api } from "../../../services/api";
import { Button } from "../../../components/Button";
import { ReactComponent as InstagramIcon } from "../../../assets/instagram-logo.svg";
import { CONFIG_ID, FACEBOOK_APP_ID } from "../../../constant";
import { ReactComponent as CheckmarkIcon } from "../../../assets/check.svg";
import { getUser } from "../../../services/utils";
import { ShowToast } from "../../../services/toast";

const APP_ID = "1556404778514241";

type InstagramInfo = {
  username: string;
  id: string;
  access_token: string;
  name: string;
  profile_picture_url: string;
};

export function Step2BusinessConnect({
  onBack,
  onNext,
  data,
  metaData,
}: {
  onBack: () => void;
  onNext: (data: any) => void;
  data?: any;
  metaData: any;
}) {
  const childWindowRef = useRef<any>(null);
  const popUpIntervalRef = useRef<any>(null);
  const user = getUser();
  const userId = user._id;

  const [loadingURL, setLoadingURL] = React.useState<boolean>(false);
  const [ayrshareData, setAyrshareData] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [instagramInfo, setInstagramInfo] = React.useState<{
    username: string;
    access_token?: string;
    profile_picture_url?: string;
    id?: string;
  } | null>(null);
  const handleBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    onBack();
  };
  const handleNext = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      if (instagramInfo?.username) {
        setLoading(true);
        const response = await api.saveOnboardingSection(metaData.code, {
          answers: {
            INSTAGRAM_HANDLE: {
              username: instagramInfo?.username,
              access_token: instagramInfo?.access_token,
              profile_picture_url: instagramInfo?.profile_picture_url,
              id: instagramInfo?.id,
            },
          },
        });
        setLoading(false);
        if (response.status === 200) {
          onNext(instagramInfo);
        }
      }
    } catch (err) {
      ShowToast({
        type: "error",
        message: "Failed to save",
      });
      setLoading(false);
    }
  };
  const responseFacebook = async (response: any) => {
    // const res = await instagramAPI.revokeFBAuthPermissions(response.accessToken);
    if (response.accessToken) {
      const res = await instagramAPI.getFBPageId(response.accessToken);
      if (res.data[0] && res.data[0].id) {
        const result = await instagramAPI.getInstagramBusinessAccount(
          response.accessToken,
          res.data[0].id
        );
        const instagramId = result?.instagram_business_account?.id;
        if (instagramId) {
          const instagramProfile = await instagramAPI.getInstagramProfile(
            response.accessToken,
            instagramId
          );
          if (instagramProfile.username) {
            setInstagramInfo({
              ...instagramProfile,
              access_token: response.accessToken,
              id: instagramId,
            });
          }
        }
        // https://www.facebook.com/v18.0/dialog/oauth?app_id=1556404778514241&auth_type=&cbt=1697909008616&channel_url=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df2c1c987277b584%26domain%3Dlocalhost%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Flocalhost%253A3002%252Ff1c5ca9527f6abc%26relation%3Dopener&client_id=1556404778514241&display=popup&domain=localhost&e2e=%7B%7D&fallback_redirect_uri=https%3A%2F%2Flocalhost%3A3002%2Fonboarding&locale=en_US&logger_id=f2d0abc9f4609c4&origin=1&redirect_uri=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df2f407a5c462ab%26domain%3Dlocalhost%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Flocalhost%253A3002%252Ff1c5ca9527f6abc%26relation%3Dopener%26frame%3Dfec07a03e76d94&response_type=token%2Csigned_request%2Cgraph_domain&return_scopes=false&scope=instagram_basic%2Cinstagram_content_publish%2Cpages_show_list%2Cpublic_profile%2Cemail%2Cbusiness_management&sdk=joey&version=v18.0
      }
    }
  };

  const receiveMessage = useCallback(
    (event: MessageEvent) => {
      if (event.origin === window.location.origin) {
        if (event.data.code === "error" && event.data.from === "Ayrshare") {
          childWindowRef.current && childWindowRef.current.close();
        } else if (event.data.from === "Ayrshare") {
          childWindowRef.current && childWindowRef.current.close();
          // do something with the code
          // need to check if instagram is connected or not
          // if connected, then show the username
          // if not connected, then show the button
          // API call to get the instagram username
          // api.getInstagramProfile
          setLoadingURL(true);
          api
            .getInstagramProfile(userId)
            .then((res) => {
              if (res.status === 200) {
                setInstagramInfo(res.data);
              }
            })
            .catch((err) => {
              ShowToast({
                type: "error",
                message: "Failed to fetch instagram profile",
              });
            })
            .finally(() => {
              setLoadingURL(false);
            });
        }
      }
    },
    [userId]
  );

  useEffect(() => {
    if (data) {
      setInstagramInfo(data);
    }
  }, [data]);

  return (
    <div className="w-full overflow-auto max-h-[calc(100vh-125px)] py-2">
      <h1 className="text-dark1 text-2xl font-semibold leading-loose">
        Nice to meet you, {data.FIRST_NAME}!
      </h1>
      <p className="text-dark2 text-sm leading-normal mb-5">
        Please provide your Instagram profile handle. This allows us to create
        custom branded content just for you!
      </p>
      <div className="flex-1 flex gap-4 flex-col md:flex-row items-center md:items-center">
        <div className="flex-1 flex items-center gap-2">
          <span className="text-lg text-dark3">@</span>
          <TextInput
            placeholder="IG username"
            value={instagramInfo?.username}
            onChange={(e) =>
              setInstagramInfo({
                ...instagramInfo,
                username: e.target.value.replace("@", ""),
              })
            }
          />
        </div>
      </div>
      <div className="my-8 border-t border-lightGray"></div>
      <ActionButtons
        onBack={handleBack}
        onNext={handleNext}
        disableNext={!instagramInfo || !instagramInfo?.username}
        loading={loading}
      />
    </div>
  );
}
