import { Button } from "../../../components/Button";
import { Modal } from "../../../components/Modal";

export function BrandingStyleConfirm({
  open,
  onClose,
  onConfirm,
}: {
  open: boolean;
  onClose: () => void;
    onConfirm: () => void;
}) {
  return (
    <Modal
      contentClassName="max-w-[400px]"
      open={open}
      onClose={onClose}
      showCloseButton={false}
      modalClassName="!overflow-y-hidden !md:overflow-y-auto"
    >
      <div className="text-center">
        <div className="text-center text-[#000] text-2xl font-semibold leading-[34px]">
          Are you sure you want to update branding values?
        </div>
        <div className="mt-3.5 leading-[18px] text-center text-black text-sm font-normal">
        Please know that by confirming changes, all results will be regenerated and previous results can't be reestablished. Do you still wish to proceed?
        </div>
        <div className="flex gap-2.5 justify-center mt-6">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onConfirm} variant="primary">Yes, Update</Button>
        </div>
      </div>
    </Modal>
  );
}
