import { Menu, Transition } from "@headlessui/react";
import { ReactComponent as Notification } from "../../assets/notification.svg";
import { ReactComponent as Search } from "../../assets/search.svg";
import { ReactComponent as MenuIcon } from "../../assets/menu.svg";
import { ReactComponent as Logo2 } from "../../assets/Logo2.svg";
import userImage from "../../assets/user.png";

import { getUser, setUser } from "../../services/utils";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { api } from "../../services/api";

const PROFILE_PIC =
  "https://scontent.fstv5-1.fna.fbcdn.net/v/t51.2885-15/393470545_363262946050114_2699725582897640060_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=7d201b&_nc_ohc=QnvOCbmlI1cAX-CBKHA&_nc_ht=scontent.fstv5-1.fna&edm=AL-3X8kEAAAA&oh=00_AfCJRu0jxavCkUKHt15MrG7gOlBxmY2yn1JIT-6gkSwJkQ&oe=653AE3BE";

export function Header({
  user,
  onMenuOpen,
}: {
  user: any;
  onMenuOpen: () => void;
}) {
  const { _id } = getUser() || {};
  const handleLogout = async () => {
    try {
      if (_id) {
        await api.saveLastActive(_id);
      }
      setUser(null);
      window.location.href = "/login";
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="px-6 py-1 bg-white border-b border-gray-200 h-[69px] justify-between items-center flex w-full">
      <div className="justify-start items-center gap-2 flex">
        <div className="lg:hidden flex gap-2 items-center">
          <button onClick={onMenuOpen}>
            <MenuIcon />
          </button>
          <Logo2 />
        </div>
        {/* <Search />
        <TextInput
          type="text"
          className="text-zinc-500 text-sm font-normal border-0 bg-transparent !pl-0 !bg-transparent"
          placeholder="Search..."
        /> */}
      </div>
      <div className="justify-start items-center gap-6 flex">
        {/* <div className="hidden md:block w-6 h-6 relative">
          <Notification />
          <div className="w-2 h-2 left-[14.99px] top-[1px] absolute bg-primary rounded-full border-2 border-white" />
        </div> */}
        <Menu>
          <Menu.Button>
            <div className="w-8 h-8 relative">
              <img
                src={
                  user?.instagramProfilePic || user?.IMAGES?.[0] || userImage
                }
                alt="profile"
                className="w-full h-full rounded-full"
              />
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute shadow mt-[100px] right-6 bg-white rounded-md z-[9999] py-2 min-w-[150px]">
              <Menu.Item>
                <button
                  onClick={handleLogout}
                  className="px-6 py-2 w-full text-left hover:bg-black/5"
                >
                  <span className="text-neutral-800 text-base font-medium">
                    Logout
                  </span>
                </button>
              </Menu.Item>
              <Menu.Item>
                <Link to="/profile">
                  <div className="px-6 py-2 w-full text-left hover:bg-black/5">
                    <span className="text-neutral-800 text-base font-medium">
                      Profile
                    </span>
                  </div>
                </Link>
              </Menu.Item>
              {/* <Menu.Item>
                <button
                  onClick={showSubscriptionModal}
                  className="px-6 py-2 w-full text-left hover:bg-black/5"
                >
                  <span className="text-neutral-800 text-base font-medium">
                    Subscription
                  </span>
                </button>
              </Menu.Item> */}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}
