import { ReactComponent as InstagramIcon } from "../../assets/instagram.svg";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUser } from "../../services/utils";
import { api } from "../../services/api";
import { LoadingScreen } from "./LoadingScreen";
import { ProfileTab } from "./components/ProfileTab";
import { ProfileWrapper } from "./ProfileWrapper";
import userImage from "../../assets/user.png";
import React from "react";
import { fileUpload } from "../../services/file-upload";
import { useUser } from "./context/user-context";
import { Loading } from "../../components/Loading/Loading";
import { ShowToast } from "../../services/toast";
import { useApp } from "../../services/app-context";
import { PaymentBox } from "../dashboard/components/PaymentBox";

function FileInput(props: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      {...props}
      className="w-[95px] h-[95px] md:w-[120px] md:h-[120px] bg-[#F6F6F6] rounded-lg flex justify-center items-center"
    >
      <div className="text-3xl flex justify-center items-center">+</div>
    </button>
  );
}

function OfferingField({ label, value }: { label: string; value: string }) {
  return (
    <div className="justify-start md:items-baseline gap-1 flex flex-col md:flex-row">
      <div className="text-gray-600 text-base font-normal font-['Inter'] leading-relaxed">
        {label}
      </div>
      <div className="grow shrink basis-0 text-zinc-900 text-base font-normal font-['Inter'] break-words leading-relaxed">
        {value}
      </div>
    </div>
  );
}

const PROFILE_PIC =
  "https://scontent.fstv5-1.fna.fbcdn.net/v/t51.2885-15/393470545_363262946050114_2699725582897640060_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=7d201b&_nc_ohc=QnvOCbmlI1cAX-CBKHA&_nc_ht=scontent.fstv5-1.fna&edm=AL-3X8kEAAAA&oh=00_AfCJRu0jxavCkUKHt15MrG7gOlBxmY2yn1JIT-6gkSwJkQ&oe=653AE3BE";

export function Profile({
  profile,
  fetching,
  setProfile,
  fetchProfile,
}: {
  profile: any;
  fetching: boolean;
  setProfile: any;
  fetchProfile: any;
}) {
  const app = useApp() as any;
  const [newImages, setNewImages] = React.useState<File[]>([]);
  const profilePicRef = React.useRef<HTMLInputElement>(null);
  const { userInfo, fetchUser } = useUser() as any;
  const user = getUser();
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [profilePicLoading, setProfilePicLoading] =
    React.useState<boolean>(false);
  const uploadRef = React.useRef<HTMLInputElement>(null);

  const details = profile?.onboardingSubmissions || {};

  const handleProfilePicChange = async (file: File) => {
    setProfilePicLoading(true);
    const uploadResponse = await fileUpload(file, user._id, "USER_THUMBNAIL");
    if (uploadResponse.url) {
      const response = await api.saveUserProfile(user._id, {
        profilePic: uploadResponse.url,
      });
      if ([200, 201].includes(response.status)) {
        fetchUser();
        setProfile((prev) => ({
          ...prev,
          profilePic: uploadResponse.url,
        }));
      }
    }
    setProfilePicLoading(false);
  };

  const handleUpload = async (files: File[]) => {
    setIsUploading(true);
    try {
      const imageList: string[] = [];
      for (let i = 0; i < files.length; i++) {
        const image = files[i];
        const uploadResponse = await fileUpload(image, user._id, "USER_IMAGE");
        // images[i] = uploadResponse.data;
        if (uploadResponse.url) {
          imageList.push(uploadResponse.url);
        }
      }
      const data = {
        onboardingSubmissions: {
          ...profile?.onboardingSubmissions,
          IMAGES: [
            ...(profile?.onboardingSubmissions?.IMAGES || []),
            ...imageList,
          ],
        },
      };
      const response = await api.saveUserProfile(user._id, data);
      if (response.status === 200) {
        fetchProfile();
        setNewImages([]);
        ShowToast({
          type: "success",
          message: "Your Profile has been updated",
        });
        fetchUser(user._id);
      }
      setIsUploading(false);
    } catch (error) {
      ShowToast({
        type: "error",
        message: "Something went wrong while uploading images",
      });
    }
  };
  const urls = newImages.map((file) => URL.createObjectURL(file));

  return (
    <>
      {/* {!app.isSubscriptionActive && <PaymentBox />} */}
      {fetching && <LoadingScreen />}
      <div className="relative flex-col gap-4 flex">
        <div className="p-4 md:p-6 bg-white rounded-lg shadow items-center gap-7 flex w-full">
          <div className="w-[72px] h-[72px] md:w-28 md:h-28 bg-white overflow-hidden relative rounded-full flex-col justify-start items-start flex">
            <img
              src={
                profile?.instagramProfilePic ||
                details?.IMAGES?.[0] ||
                userImage
              }
              alt="profile-pic"
              className="w-full h-full object-cover rounded-full"
            />
            {profilePicLoading && (
              <div className="absolute top-0 bottom-0 left-0 right-0 bg-black/30 flex justify-center items-center [&>svg]:mr-0 [&>svg]:w-6 [&>svg]:h-6">
                <Loading />
              </div>
            )}
            <button
              onClick={() => profilePicRef.current?.click()}
              className="hidden md:block text-center bg-black bg-opacity-50 absolute bottom-0 left-0 right-0 h-8 flex items-center justify-center"
              disabled={profilePicLoading}
            >
              <span className="text-white text-sm font-normal font-['Inter'] leading-normal">
                Edit
              </span>
            </button>
            <input
              type="file"
              className="hidden"
              ref={profilePicRef}
              accept="image/*"
              onChange={(e) => {
                const files = e.target.files;
                if (files?.length) {
                  handleProfilePicChange(files[0]);
                }
                e.target.value = "";
              }}
            />
          </div>
          <div className="flex-col justify-start items-start gap-2.5 flex">
            <div className="text-neutral-800 text-2xl font-bold font-['Inter']">
              {details.FIRST_NAME} {details.LAST_NAME}
            </div>
            <div className="justify-start items-center gap-2 flex">
              <InstagramIcon className="w-5 h-5" />
              <div className="text-gray-600 text-base font-normal font-['Inter']">
                {typeof details.INSTAGRAM_HANDLE === "string"
                  ? "@" + details.INSTAGRAM_HANDLE
                  : details?.INSTAGRAM_HANDLE?.username}
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 md:p-6 bg-white rounded-lg shadow justify-start items-center gap-7 flex">
          <div className="grow shrink basis-0 flex-col justify-start items-start gap-3 flex">
            <div className="text-neutral-800 text-lg font-semibold font-['Inter'] leading-normal">
              Client Niche
            </div>
            <div className="self-stretch text-gray-600 text-base font-normal font-['Inter'] leading-relaxed">
              {profile.clientNiche}
            </div>
          </div>
        </div>
        <div className="w-full p-4 md:p-6 bg-white rounded-lg shadow justify-start items-center gap-7 flex">
          <div className="grow shrink basis-0 flex-col justify-start items-start gap-5 flex">
            <div className="text-neutral-800 text-lg font-semibold font-['Inter'] leading-normal">
              Uploaded Pictures
            </div>
            <div className="justify-start gap-3 flex flex-wrap">
              {details?.IMAGES?.map((image, index) => (
                <div
                  key={index}
                  className="w-[95px] h-[95px] md:w-[120px] md:h-[120px]"
                >
                  <img
                    src={image}
                    alt="pic"
                    className="w-full h-full object-cover"
                  />
                </div>
              ))}
              {urls?.map((image, index) => (
                <div
                  key={index}
                  className="w-[95px] h-[95px] md:w-[120px] md:h-[120px] relative"
                >
                  <img
                    src={image}
                    alt="pic"
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute top-0 left-0 bg-black/20 w-full h-full flex items-center justify-center [&>svg]:h-8 [&>svg]:w-8">
                    <Loading />
                  </div>
                </div>
              ))}
              <input
                type="file"
                className="hidden"
                ref={uploadRef}
                multiple
                accept="image/*"
                onChange={(e) => {
                  const files = e.target.files;
                  if (files?.length) {
                    setNewImages([...Array.from(files)]);
                    handleUpload([...Array.from(files)]);
                  }
                  e.target.value = "";
                }}
              />
              {!isUploading && (
                <FileInput
                  onClick={() => uploadRef.current?.click()}
                  disabled={false}
                />
              )}
            </div>
          </div>
        </div>
        <div className="p-4 md:p-6 bg-white rounded-lg shadow justify-start items-center gap-7 flex">
          <div className="flex-col justify-start items-start gap-6 flex">
            <span className="text-neutral-800 text-lg font-semibold font-['Inter'] leading-normal">
              Offering
            </span>
            <div className="flex-col justify-start items-start gap-4 flex">
              <OfferingField label="Name:" value={details.OFFERING_NAME} />
              {/* <OfferingField
                label="Description:"
                value={details.OFFERING_DESCRIPTION}
              /> */}
              <OfferingField
                label="Pricing:"
                value={details.OFFERING_PRICING}
              />
              <OfferingField
                label="Duration:"
                value={details.OFFERING_DURATION}
              />
              <OfferingField label="Format:" value={details.OFFERING_FORMAT} />
              <OfferingField
                label="Tools/Outcome:"
                value={details.OFFERING_OUTCOME}
              />
            </div>
          </div>
        </div>
        <div className="p-4 md:p-6 bg-white rounded-lg shadow justify-start items-center gap-7 flex">
          <div className="flex-col justify-start items-start gap-5 flex">
            <div className="text-neutral-800 text-lg font-semibold font-['Inter'] leading-normal">
              Speciality
            </div>
            <div className="self-stretch justify-start items-start gap-1 flex">
              <div className="grow shrink basis-0 text-zinc-900 text-base font-normal font-['Inter'] leading-relaxed">
                {profile.speciality}
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 md:p-6 bg-white rounded-lg shadow items-center gap-7 flex">
          <div className="grow shrink basis-0 flex-col justify-start items-start gap-6 flex">
            <div className="text-neutral-800 text-lg font-semibold font-['Inter'] leading-normal">
              Brand Voice
            </div>
            <div className="gap-11 flex flex-wrap">
              {details?.BRAND_VOICE?.map((item: any, index: number) => (
                <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 flex">
                  <div className="w-40 text-gray-600 text-base font-normal font-['Inter'] leading-relaxed">
                    Example #{index + 1}
                  </div>
                  <div
                    className="self-stretch text-zinc-900 text-base font-normal font-['Inter'] leading-relaxed"
                    dangerouslySetInnerHTML={{ __html: item.caption }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
