import React, { useEffect } from "react";
import { TextInput } from "../../../components/TextInput";
import { ActionButtons } from "./ActionButtons";
import { api } from "../../../services/api";

export function Step1({
  onBack,
  onNext,
  metaData,
  data,
}: {
  onBack: () => void;
  onNext: (data: any) => void;
  metaData: any;
  data;
}) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<any>({});
  const handleBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    onBack();
  };
  const handleNext = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (form.FIRST_NAME && form.LAST_NAME) {
      setLoading(true);
      const response = await api.saveOnboardingSection(metaData.code, {
        answers: {
          FIRST_NAME: form.FIRST_NAME,
          LAST_NAME: form.LAST_NAME,
        },
      });
      setLoading(false);
      if (response.status === 200) {
        onNext({
          FIRST_NAME: form.FIRST_NAME,
          LAST_NAME: form.LAST_NAME,
        });
      }
    }
  };

  useEffect(() => {
    if (data) {
      setForm(data);
    }
  }, [data]);

  const disabled = !form.FIRST_NAME || !form.LAST_NAME;
  return (
    <div className="w-full overflow-auto max-h-[calc(100vh-125px)] py-2">
      <h1 className="text-dark1 text-2xl font-semibold leading-loose">
        We'd love to get to know you!
      </h1>
      <p className="text-dark2 text-sm leading-normal mb-5">
        Please share your name
      </p>
      <div className="flex-1 flex gap-4 flex-col md:flex-row">
        <TextInput
          placeholder="First name"
          onChange={(e) =>
            setForm((prev) => ({ ...prev, FIRST_NAME: e.target.value }))
          }
          value={form.FIRST_NAME || ""}
          disabled={loading}
          maxLength={50}
        />
        <TextInput
          placeholder="Last name"
          onChange={(e) =>
            setForm((prev) => ({ ...prev, LAST_NAME: e.target.value }))
          }
          value={form.LAST_NAME || ""}
          disabled={loading}
          maxLength={50}
        />
      </div>
      <div className="my-8 border-t border-lightGray"></div>
      <ActionButtons
        onNext={handleNext}
        disableNext={disabled}
        loading={loading}
      />
    </div>
  );
}
