import { ReactComponent as EditIcon } from "../../../assets/pencil.svg";

export function BrandStyleTab({
  onEdit,
  label,
  infoView = null,
}: {
  onEdit?: () => void;
  label: string;
  infoView?: any;
}) {
  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-4">
        <h2 className="font-semibold text-2xl text-dark1">{label}</h2>
        {infoView}
      </div>
      {onEdit && (
        <button onClick={onEdit}>
          <EditIcon />
        </button>
      )}
    </div>
  );
}
