import { Button } from "../../../components/Button";
import { Modal } from "../../../components/Modal";
import { TextArea } from "../../../components/TextArea";
import { ReactComponent as CloseIcon } from "../../../assets/x.svg";
import { useMemo, useState } from "react";
import { TextInput } from "../../../components/TextInput";

export function OtherDialog({ isOpen, onClose, value, onChange }) {
  const [currValues, setCurrValues] = useState(value || "");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleAdd = () => {
    onChange && onChange(currValues);
    onClose && onClose();
  };
  const disabled = useMemo(() => {
    // check if all fields are filled
    // either ABOUT_CLIENT_PROFESSION or ABOUT_CLIENT_PROFESSION_OTHER
    // either STRUGGLE_WITH or STRUGGLE_WITH_OTHER
    // either HELP_CLIENT or HELP_CLIENT_OTHER
    const {
      ABOUT_CLIENT_PROFESSION,
      ABOUT_CLIENT_PROFESSION_OTHER,
      STRUGGLE_WITH,
      STRUGGLE_WITH_OTHER,
      HELP_CLIENT,
      HELP_CLIENT_OTHER,
    } = currValues;
    return !(
      (ABOUT_CLIENT_PROFESSION || ABOUT_CLIENT_PROFESSION_OTHER) &&
      (STRUGGLE_WITH || STRUGGLE_WITH_OTHER) &&
      (HELP_CLIENT || HELP_CLIENT_OTHER)
    );
  }, [currValues]);
  return (
    <Modal
      contentClassName="max-w-[594px] pt-5 relative"
      open={isOpen}
      onClose={onClose}
      showCloseButton={false}
    >
      <button className="w-6 h-6 absolute top-6 right-6" onClick={onClose}>
        <CloseIcon className="w-6 h-6 [&>path]:stroke-[#84818A]" />
      </button>
      <div className="flex justify-between items-center mb-2">
        <div className="text-neutral-800 max-w-[300px] md:max-w-[450px] text-xl md:text-2xl font-semibold">
          Please help us understand who your niche clients are!
        </div>
      </div>
      <div className="mb-6 text-zinc-700 text-sm font-normal font-['Inter'] leading-normal">
        In a few words, describe who they are, what they struggle with, and how
        you help them
      </div>
      <div className="flex items-center gap-3">
        <div className="w-[125px] text-sm">I help</div>
        <TextInput
          name="ABOUT_CLIENT_PROFESSION_OTHER"
          value={
            currValues.ABOUT_CLIENT_PROFESSION
              ? currValues.ABOUT_CLIENT_PROFESSION
              : currValues.ABOUT_CLIENT_PROFESSION_OTHER
          }
          onChange={handleChange}
          placeholder="ex: momepreneurs launching their business"
          readOnly={value && value.ABOUT_CLIENT_PROFESSION}
        />
      </div>
      <div className="flex items-center gap-3 mt-4">
        <div className="w-[125px] whitespace-nowrap text-sm">
          Struggling with
        </div>
        <TextInput
          name="STRUGGLE_WITH_OTHER"
          value={
            currValues.STRUGGLE_WITH
              ? currValues.STRUGGLE_WITH
              : currValues.STRUGGLE_WITH_OTHER
          }
          onChange={handleChange}
          placeholder="ex. showing up on social media"
          readOnly={value && value.STRUGGLE_WITH}
        />
      </div>
      <div className="flex items-center gap-3 mt-4">
        <div className="w-[125px] whitespace-nowrap text-sm">By utilizing</div>
        <TextInput
          name="HELP_CLIENT_OTHER"
          value={
            currValues.HELP_CLIENT
              ? currValues.HELP_CLIENT
              : currValues.HELP_CLIENT_OTHER
          }
          onChange={handleChange}
          placeholder="ex. EFT & accountability coaching"
          readOnly={value && value.HELP_CLIENT}
        />
      </div>
      <Button
        variant="primary"
        disabled={disabled}
        className="mt-6 mb-4"
        onClick={handleAdd}
      >
        Next
      </Button>
    </Modal>
  );
}
