import React from "react";
import { BrandStyleTab } from "./BrandStyleTab";
import { Modal } from "../../../components/Modal";
import { Button } from "../../../components/Button";
import Tippy from "@tippyjs/react";
import { ReactComponent as Info } from "../../../assets/ic_info.svg";
import { ColorInput } from "./ColorPicker";
import { ColorChangeConfirm } from "./ColorChangeConfirm";
import { useNavigate } from "react-router-dom";
import { PaymentPopup } from "../../dashboard/components/PaymentPopup";

const colorTitles = [
  "Primary Brand Color",
  "Secondary Dark",
  "Secondary Light",
  "Accent",
  "Neutral",
];

export function BrandStylesColor({
  colors,
  onUpdate,
  colorsReason,
  isSubscriptionActive,
}: {
  colors: string[];
  onUpdate: (data: any) => any;
  colorsReason: any;
  isSubscriptionActive: boolean;
}) {
  const [editConfirm, setEditConfirm] = React.useState<boolean>(false);
  const [edit, setEdit] = React.useState<boolean>(false);
  const [editColors, setEditColors] = React.useState<string[]>([]);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [showPopup, setShowPopup] = React.useState<boolean>(false);

  const handleEdit = () => {
    setEdit(true);
    setEditConfirm(false);
    setEditColors(colors);
  };

  const handleEditConfirm = () => {
    if (isSubscriptionActive) {
      setEditConfirm(true);
    } else {
      setShowPopup(true);
    }
  };

  const handleClose = () => {
    setEdit(false);
  };

  const handleUpdate = async () => {
    setSaving(true);
    const response = await onUpdate({
      brandStyleColors: editColors,
    });
    setSaving(false);
    if (response) {
      setEdit(false);
    }
  };

  const renderInfoView = (reason, children) => (
    <Tippy
      className="my-tooltip"
      arrow={true}
      interactive={true}
      hideOnClick={true}
      content={
        <div className="p-4 bg-white rounded-[10px] shadow">
          <h1 className="text-black text-xl font-medium">
            Why this color is selected:
          </h1>
          <div className="text-base mt-2">{reason}</div>
        </div>
      }
    >
      {children}
    </Tippy>
  );

  return (
    <div>
      <BrandStyleTab label="Colors" onEdit={handleEditConfirm} />
      <div className="flex gap-3 flex-wrap mt-4">
        {(colors || []).map((color, i) =>
          renderInfoView(
            colorsReason[i],
            <div className="w-52 h-20 relative" key={color}>
              <div
                className="w-52 h-20 left-0 top-0 absolute rounded-lg"
                style={{ backgroundColor: color }}
              />
              <div className="left-[14px] top-[39px] absolute text-white text-lg font-semibold font-['Inter'] leading-normal">
                {colorTitles[i]}
              </div>
            </div>
          )
        )}
      </div>
      <Modal
        contentClassName="max-w-[525px] !mx-2"
        open={edit}
        onClose={handleClose}
      >
        <div>
          <div className="text-neutral-800 text-2xl font-semibold">
            Edit Colors
          </div>
          <div className="flex gap-4 flex-wrap mt-4 flex-col md:flex-row">
            {editColors.map((color, index) => (
              <ColorInput
                value={color}
                onChange={(value) => {
                  const newColors = [...editColors];
                  newColors[index] = value;
                  setEditColors(newColors);
                }}
                key={index}
              />
            ))}
          </div>
          <div className="mt-8 flex justify-end">
            <Button variant="primary" onClick={handleUpdate} loading={saving}>
              Update
            </Button>
          </div>
        </div>
      </Modal>
      {editConfirm && (
        <ColorChangeConfirm
          onClose={() => setEditConfirm(false)}
          onConfirm={handleEdit}
          open={editConfirm}
        />
      )}

      {showPopup && (
        <PaymentPopup
          isSubscriptionActive={false}
          open={showPopup}
          onClose={() => setShowPopup(false)}
        />
      )}
    </div>
  );
}
