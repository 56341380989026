import { Link, useNavigate } from "react-router-dom";
import { LoadingScreen } from "./LoadingScreen";
import { useEffect, useState } from "react";
import { BrandStylesColor } from "./components/BrandStylesColor";
import { BrandStylesFont } from "./components/BrandStylesFont";
import { BrandStylesBrandValues } from "./components/BrandStylesBrandValues";
import { api } from "../../services/api";
import React from "react";
import { ShowToast } from "../../services/toast";
import { trackEvent } from "../../services/mixpanel";
import { Button } from "../../components/Button";
import { useApp } from "../../services/app-context";
import { RegenerateConfirm } from "./components/RegenerateConfirm";
import { PaymentBox } from "../dashboard/components/PaymentBox";

export function BrandStyleGuide({ profile, id }: { profile: any; id: string }) {
  const { isSubscriptionActive } = useApp() as any;
  const navigate = useNavigate();
  const [brandStyleInfo, setBrandStyleInfo] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [regenerateLoading, setRegenerateLoading] = useState<boolean>(false);
  const [regenerateConfirm, setRegenerateConfirm] = useState<boolean>(false);
  const controllerRef = React.useRef(new AbortController());
  const [isBrandStyleChanged, setIsBrandStyleChanged] =
    useState<boolean>(false);

  const fetchBrandStyleInfo = React.useCallback(() => {
    setLoading(true);
    api
      .getBrandStyles(id)
      .then((response) => {
        setBrandStyleInfo(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [id]);

  const handleBrandValueSave = async (data: any) => {
    // const response = await api.saveBrandStyles(id, data);
    // fetchBrandStyleInfo();
    // return response;
    try {
      if (data.length > 0) {
        const response = await api.saveOnboardingSection("KEYWORDS", {
          answers: {
            KEYWORDS: data,
          },
        });
        setLoading(false);
        if (response.status === 200) {
          if (id) {
            controllerRef.current = new AbortController();
            const response = await api.generatePrompt(
              id,
              {
                promptType: "EDIT_BRAND_STYLE",
              },
              controllerRef.current.signal
            );

            // track event
            trackEvent("Regenerate Brand Guidelines", {
              userId: id,
              keywords: data,
            });
          }
          setIsBrandStyleChanged(true);
          await fetchBrandStyleInfo();
          ShowToast({
            message: "Keywords saved successfully",
            type: "success",
          });
          return true;
        }
      }
      return false;
    } catch (error: any) {
      setLoading(false);
      ShowToast({
        message:
          error?.code === "ERR_CANCELED"
            ? "Request Canceled"
            : "Something went wrong",
        type: "error",
      });
      return false;
    }
  };

  const handleBrandStyleSave = async (data: any) => {
    setIsBrandStyleChanged(true);
    const response = await api.saveBrandStyles(id, data);
    if (response.status === 200) {
      await fetchBrandStyleInfo();
      ShowToast({ message: "Brand style saved successfully", type: "success" });
      return true;
    }
    return false;
  };

  const handleRegenerateAllVisuals = async () => {
    try {
      setRegenerateLoading(true);
      const response = await api.regeneratePostsImage(id);
      if ([200, 201].includes(response.status)) {
        setIsBrandStyleChanged(false);
        navigate("/dashboard");
        // ShowToast({
        //   message: "Your dashboard is updating, please refresh in 10 minutes.",
        //   type: "success",
        // });
      }
      setRegenerateLoading(false);
    } catch (error) {
      setRegenerateLoading(false);
      ShowToast({ message: "Something went wrong", type: "error" });
    }
  };

  useEffect(() => {
    fetchBrandStyleInfo();
  }, [fetchBrandStyleInfo]);

  useEffect(() => {
    return () => {
      controllerRef.current.abort();
    };
  }, [controllerRef]);

  useEffect(() => {
    setIsBrandStyleChanged(false);
  }, []);

  return (
    <>
      {/* {!isSubscriptionActive && <PaymentBox />} */}
      <div className="relative flex flex-col gap-[60px]">
        <BrandStylesColor
          colors={brandStyleInfo.colors}
          onUpdate={handleBrandStyleSave}
          colorsReason={brandStyleInfo.colorsReason || []}
          isSubscriptionActive={isSubscriptionActive}
        />
        <BrandStylesFont
          fonts={brandStyleInfo.typography}
          onUpdate={handleBrandStyleSave}
        />
        <BrandStylesBrandValues
          brandValues={brandStyleInfo.brandValues}
          onUpdate={handleBrandValueSave}
          onClose={() => {
            controllerRef.current.abort();
          }}
          isSubscriptionActive={isSubscriptionActive}
        />
        <div>
          <Button
            variant="primary"
            loading={regenerateLoading}
            disabled={regenerateLoading || !isBrandStyleChanged}
            onClick={() => setRegenerateConfirm(true)}
            className={`${!isBrandStyleChanged ? "!bg-[#E8E8E8]" : ""}`}
          >
            REGENERATE MY VISUALS
          </Button>
        </div>
        {loading && <LoadingScreen />}
        <RegenerateConfirm
          open={regenerateConfirm}
          onClose={() => setRegenerateConfirm(false)}
          onConfirm={handleRegenerateAllVisuals}
          loading={regenerateLoading}
        />
      </div>
    </>
  );
}
