import { Sketch } from "@uiw/react-color";
import { useEffect, useRef, useState } from "react";

export function ColorInput({ onChange, value }) {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const handleChange = (color) => {
    onChange(color.hex);
  };

  // implement close on outside click
  useEffect(() => {
    // detect click outside
    document.addEventListener("click", (e) => {
      //@ts-expect-error
      if (ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    });
  }, []);

  return (
    <div className="relative" ref={ref}>
      <button
        className="w-full md:w-48 h-20 relative z-[1]"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div
          className="w-full md:w-48 h-20 left-0 top-0 absolute rounded-lg"
          style={{ backgroundColor: value }}
        />
        <div className="left-[14px] top-[39px] absolute text-white text-lg font-semibold font-['Inter'] leading-normal">
          {value}
        </div>
      </button>
      {open && (
        <div className="absolute z-[2]">
          <Sketch color={value} onChange={handleChange} />
        </div>
      )}
    </div>
  );
}
