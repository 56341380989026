import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { ReactComponent as DashboardIcon } from "../../assets/dashboard.svg";
import { ReactComponent as Logo } from "../../assets/Logo.svg";
import { ReactComponent as GuidelyIcon } from "../../assets/guidely-icon.svg";
import { ReactComponent as ProgressIcon } from "../../assets/progress.svg";
import { ReactComponent as CalendarIcon } from "../../assets/calendar.svg";
import { ReactComponent as PhotoLibraryIcon } from "../../assets/photo-library.svg";
import { ReactComponent as SupportIcon } from "../../assets/support.svg";
import { ReactComponent as SettingsIcon } from "../../assets/settings.svg";
import userImage from "../../assets/user.png";

import { ReactComponent as LibraryIcon } from "../../assets/local-library.svg";
import { ReactComponent as LiveHelpIcon } from "../../assets/live-help.svg";
import { ReactComponent as ChevronLeftIcon } from "../../assets/chevron-left.svg";
import { SubscriptionView } from "../../pages/payment/SubscriptionView";
import { MANAGE_STRIPE_URL } from "../../env";

const PROFILE_PIC =
  "https://scontent.fstv5-1.fna.fbcdn.net/v/t51.2885-15/393470545_363262946050114_2699725582897640060_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=7d201b&_nc_ohc=QnvOCbmlI1cAX-CBKHA&_nc_ht=scontent.fstv5-1.fna&edm=AL-3X8kEAAAA&oh=00_AfCJRu0jxavCkUKHt15MrG7gOlBxmY2yn1JIT-6gkSwJkQ&oe=653AE3BE";

export const menuItems = [
  {
    title: "Dashboard",
    Icon: DashboardIcon,
    path: "/dashboard",
  },
  {
    title: "Progress",
    Icon: ProgressIcon,
    path: "/progress",
  },
  {
    title: "Schedule",
    Icon: CalendarIcon,
    path: "/schedule",
  },
  // {
  //   title: "Ask Guidely",
  //   Icon: SupportIcon,
  //   path: "/ask-guidely",
  // },
  // {
  //   title: "Media Library",
  //   Icon: PhotoLibraryIcon,
  //   path: "/media-library",
  // },
  // {
  //   title: "Settings",
  //   Icon: SettingsIcon,
  //   path: "/settings",
  // },
];

export const helpMenuItems = [
  {
    title: "Knowledge Base",
    Icon: LibraryIcon,
    path: "http://coaches.guidely.com/knowledge-base",
    target: "_blank",
  },
  {
    title: "Settings",
    Icon: SettingsIcon,
    path: "/settings",
  },
  // {
  //   title: "Help",
  //   Icon: LiveHelpIcon,
  //   path: "/help",
  // },
];

export function Sidebar({ user }: { user: any }) {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };
  const isSelected = (path) => {
    if (location.pathname.includes(path)) {
      return true;
    }
    return false;
  };

  return (
    <div className="hidden lg:flex max-h-[100vh] overflow-auto bg-white border-r border-gray-200 flex-col">
      <div className="self-stretch pl-6 pr-2 py-5 bg-white border-b border-gray-200 justify-between items-center flex">
        <div className="justify-center items-center flex">
          {collapsed ? (
            <GuidelyIcon className="[&_path]:stroke-primary [&_circle]:stroke-primary" />
          ) : (
            <Logo />
          )}
        </div>
        <button
          onClick={toggleCollapse}
          className="p-1 bg-red-50 rounded justify-start items-start gap-2.5 flex"
        >
          <ChevronLeftIcon />
        </button>
      </div>
      <Link
        to="/profile"
        className="self-stretch h-28 p-6 bg-white flex-col justify-start items-start gap-2.5 flex"
      >
        {collapsed ? (
          <div className="w-9 h-9 rounded-full">
            <img
              src={user?.instagramProfilePic || user?.IMAGES?.[0] || userImage}
              alt="profile-pic"
              className="w-full h-full rounded-full object-fit"
            />
          </div>
        ) : (
          <div className="self-stretch px-4 py-3 bg-white rounded-lg border border-gray-200 justify-start items-center gap-2 inline-flex">
            <div className="w-9 h-9 rounded-full">
              <img
                src={
                  user?.instagramProfilePic || user?.IMAGES?.[0] || userImage
                }
                alt="profile-pic"
                className="w-full h-full rounded-full object-fit"
              />
            </div>
            <div className="flex-col gap-0.5 flex">
              <span className="text-dark1 text-sm font-medium">
                @
                {typeof user.INSTAGRAM_HANDLE === "string"
                  ? user.INSTAGRAM_HANDLE
                  : user?.INSTAGRAM_HANDLE?.username}
              </span>
              <span className="text-dark3 text-xs font-normal">Instagram</span>
            </div>
          </div>
        )}
      </Link>
      <div className="flex-col justify-start items-start gap-5 flex">
        <div className="flex-col justify-start items-start flex w-full">
          {menuItems.map((item, index) => (
            <Link
              to={item.path}
              key={index}
              className={`w-full px-6 py-3.5 bg-white justify-start items-center gap-3 flex group hover:bg-[#FBF2F0] transition duration-75 hover:scale-105 ${
                isSelected(item.path) ? "!bg-[#F4EFE5]" : ""
              } ${collapsed ? "justify-center" : ""}`}
            >
              <item.Icon
                className={`group-hover:[&_path]:fill-primary ${
                  isSelected(item.path) ? "[&_path]:!fill-primary" : ""
                } ${
                  item.path === "/settings"
                    ? "group-hover:[&_path]:fill-primary"
                    : "group-hover:[&_path]:fill-primary"
                }`}
              />

              {!collapsed && (
                <div className="grow shrink basis-0 text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
                  {item.title}
                </div>
              )}
            </Link>
          ))}
        </div>
        <div className="self-stretch h-px border-b border-lightGray"></div>
        <div className="flex-col justify-start items-start flex w-full">
          {helpMenuItems.map((item, index) => (
            <Link
              to={item.path}
              target={item.target}
              key={index}
              className={`w-full px-6 py-3.5 bg-white justify-start items-center gap-3 flex group hover:bg-[#FBF2F0] transition duration-75 hover:scale-105 ${
                collapsed ? "justify-center" : ""
              }`}
            >
              <item.Icon
                className={`${
                  item.path === "/settings"
                    ? "group-hover:[&_path]:fill-primary"
                    : "group-hover:[&_path]:fill-primary"
                }`}
              />
              {!collapsed && (
                <div className="grow shrink basis-0 text-neutral-800 text-base font-medium font-['Inter'] leading-tight">
                  {item.title}
                </div>
              )}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
