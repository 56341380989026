import React, { useEffect } from "react";
import { ActionButtons } from "./ActionButtons";
import instagramAPI from "../../../services/instagram";
import { Modal } from "../../../components/Modal";
import { ReactComponent as CheckIcon } from "../../../assets/check.svg";
import { api } from "../../../services/api";
import { getUser } from "../../../services/utils";
import { ShowToast } from "../../../services/toast";
import { Loading } from "../../../components/Loading/Loading";
import { Step10 } from "./Step10";

const imageList = [
  "https://fastly.picsum.photos/id/22/4434/3729.jpg?hmac=fjZdkSMZJNFgsoDh8Qo5zdA_nSGUAWvKLyyqmEt2xs0",
  "https://fastly.picsum.photos/id/20/3670/2462.jpg?hmac=CmQ0ln-k5ZqkdtLvVO23LjVAEabZQx2wOaT4pyeG10I",
  "https://fastly.picsum.photos/id/29/4000/2670.jpg?hmac=rCbRAl24FzrSzwlR5tL-Aqzyu5tX_PA95VJtnUXegGU",
  "https://fastly.picsum.photos/id/25/5000/3333.jpg?hmac=yCz9LeSs-i72Ru0YvvpsoECnCTxZjzGde805gWrAHkM",
  "https://fastly.picsum.photos/id/49/1280/792.jpg?hmac=NnUJy0O9-pXHLmY2loqVs2pJmgw9xzuixgYOk4ALCXU",
  "https://fastly.picsum.photos/id/57/2448/3264.jpg?hmac=ewraXYesC6HuSEAJsg3Q80bXd1GyJTxekI05Xt9YjfQ",
  "https://fastly.picsum.photos/id/75/1999/2998.jpg?hmac=0agRZd8c5CRiFvADOWJqfTv6lqYBty3Kw-9LEtLp_98",
  "https://fastly.picsum.photos/id/63/5000/2813.jpg?hmac=HvaeSK6WT-G9bYF_CyB2m1ARQirL8UMnygdU9W6PDvM",
  "https://fastly.picsum.photos/id/64/4326/2884.jpg?hmac=9_SzX666YRpR_fOyYStXpfSiJ_edO3ghlSRnH2w09Kg",
];

export function Step9({
  onBack,
  onNext,
  data,
  metaData,
  setGeneratingPrompt,
  generatePrompt,
  controllerRef,
  id,
}: {
  onBack: () => void;
  onNext: (data: any) => void;
  data: any;
  metaData: any;
  setGeneratingPrompt: (value: boolean) => void;
  generatePrompt?: boolean;
  controllerRef?: any;
  id?: string;
}) {
  const user = getUser();
  const userId = user?._id;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [postsLoading, setPostsLoading] = React.useState<boolean>(false);
  const [hasPosts, setHasPosts] = React.useState<boolean>(false);
  const [quickSelected, setQuickSelected] = React.useState<any>(false);
  const [selected, setSelected] = React.useState<any[]>([]);
  const [posts, setPosts] = React.useState<any[]>([]);

  const handleBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    onBack();
  };
  const handleNext = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      setLoading(true);
      setGeneratingPrompt && setGeneratingPrompt(true);
      const response = await api.saveOnboardingSection(metaData.code, {
        answers: {
          BRAND_VOICE: selected.map((img) => ({ caption: img.post })),
        },
      });

      setLoading(false);
      setGeneratingPrompt && setGeneratingPrompt(false);
      if (response.status === 200) {
        if (generatePrompt && id) {
          setLoading(true);
          if (controllerRef) {
            controllerRef.current = new AbortController();
          }
          const response = await api.generatePrompt(
            id,
            {
              promptType: "EDIT_PROFILE",
            },
            controllerRef?.current?.signal
          );
          setLoading(false);
        }
        onNext({
          BRAND_VOICE: selected,
        });
      }
    } catch (err) {
      ShowToast({ message: "Failed to save answers", type: "error" });
    } finally {
      setLoading(false);
      setGeneratingPrompt && setGeneratingPrompt(false);
    }
  };
  const toggleSelected = (item: any) => {
    if (selected.findIndex((img) => img.id === item.id) !== -1) {
      setSelected(selected.filter((i) => i.id !== item.id));
    } else {
      if (selected.length < 2) {
        setSelected([...selected, item]);
      }
    }
  };

  const handleQuickSelect = (item: any) => {
    setQuickSelected(item);
  };

  const closeQuickSelect = () => {
    setQuickSelected(false);
  };

  useEffect(() => {
    // fetch instagram posts
    if (userId) {
      setPostsLoading(true);
      api
        .getInstagramPosts(userId)
        .then((res) => {
          setPosts([...(res?.data?.posts || [])]);
          setHasPosts(res?.data?.posts?.length > 0);
        })
        .catch((err) => {
          ShowToast({ message: "Failed to fetch posts", type: "error" });
        })
        .finally(() => {
          setPostsLoading(false);
        });
    }
  }, [userId]);

  const disabled = loading;

  // if (loading) {
  //   return <Step10 />;
  // }

  useEffect(() => {
    return () => {
      if (controllerRef) {
        controllerRef.current && controllerRef.current.abort();
      }
    };
  }, [controllerRef]);

  return (
    <div className="w-full overflow-auto max-h-[calc(100vh-125px)] py-2">
      <h1 className="text-dark1 text-lg  md:text-2xl font-semibold leading-normal md:leading-loose">
        Let’s explore your brand voice
      </h1>
      <p className="text-dark2 text-sm leading-normal mb-5 mt-1.5">
        Pick two posts from the list that carry your genuine message and tone.
        This will guide us in crafting content that feels authentically you!
      </p>

      <div className="flex-1 md:p-5 md:bg-ultraLightGray flex justify-center items-center">
        {postsLoading ? (
          <div className="my-6 [&>svg]:w-8 [&>svg]:h-8">
            <Loading />
          </div>
        ) : (
          <div className="grid grid-cols-3 gap-1">
            {!postsLoading && !posts.length && (
              <div className="col-span-3 flex justify-center items-center">
                <p className="text-dark2 text-sm leading-normal my-5">
                  No posts found!
                </p>
              </div>
            )}
            {posts.slice(0, 9).map((image) => (
              <button
                // onClick={() => toggleSelected(image)}
                className={`w-[110px] h-[110px] relative group`}
                onClick={() => handleQuickSelect(image)}
                key={image.id}
                disabled={loading}
              >
                {selected.findIndex((img) => img.id === image.id) !== -1 && (
                  <div className="absolute p-1 rounded-full top-1.5 right-1.5 bg-[#0AA630]">
                    <CheckIcon className="w-3 h-3 [&>path]:stroke-white" />
                  </div>
                )}
                {image.mediaType === "VIDEO" ? (
                  <video
                    className="w-full h-full object-cover"
                    src={image.mediaUrl}
                  />
                ) : (
                  <img
                    src={image.mediaUrl}
                    alt="pic"
                    className="w-full h-full object-cover"
                  />
                )}
                <div />
              </button>
            ))}
          </div>
        )}
      </div>
      <div className="my-8 border-t border-lightGray"></div>
      <ActionButtons
        onBack={handleBack}
        onNext={handleNext}
        disableNext={disabled}
        loading={loading}
      />
      {!!quickSelected && (
        <Modal
          open={!!quickSelected}
          onClose={closeQuickSelect}
          contentClassName="mx-2 md:mx-0 md:w-auto"
        >
          <div className="flex justify-center">
            <div className="w-full h-full md:h-[300px] relative">
              {selected.findIndex((img) => img.id === quickSelected.id) !==
              -1 ? (
                <button
                  className="absolute z-[9999] p-1 rounded-full top-2 right-2 bg-[#0AA630]"
                  onClick={() => toggleSelected(quickSelected)}
                >
                  <CheckIcon className="w-3 h-3 [&>path]:stroke-white" />
                </button>
              ) : (
                <button
                  className="w-6 h-6 z-[9999] border-[3px] border-white rounded-full absolute right-2 top-2"
                  onClick={() => {
                    toggleSelected(quickSelected);
                    setTimeout(() => {
                      closeQuickSelect();
                    }, 1000);
                  }}
                ></button>
              )}
              <div className="max-h-[300px] max-w-[300px]">
                <div className="absolute top-0 left-0 w-full h-full bg-black/30" />
                {quickSelected.media_type === "VIDEO" ? (
                  <video
                    className="w-full h-full object-cover"
                    src={quickSelected.mediaUrl}
                  />
                ) : (
                  <img
                    src={quickSelected.mediaUrl}
                    alt="pic"
                    className="w-full h-full object-cover"
                  />
                )}
              </div>
            </div>
          </div>
          <p className="leading-loose mt-[2px] max-w-[300px] max-h-[200px] overflow-auto">
            <span className="font-semibold text-lg">
              {quickSelected.username}
            </span>{" "}
            {quickSelected.post}
          </p>
        </Modal>
      )}
    </div>
  );
}
