import { useEffect, useRef, useState } from "react";
import { ReactComponent as PlayButtonIcon } from "../../../assets/play-button.svg";
import React from "react";

function VideoLoopView({
  src = "/media/demo-video.mp4",
  loopSrc,
}: {
  src?: string;
  loopSrc?: string;
}) {
  const [vidIndex, setVidIndex] = useState(0);
  const [play, setPlay] = useState(false);
  const playerRef = useRef<HTMLVideoElement>(null);
  const loopPlayerRef = useRef<HTMLVideoElement>(null);

  const handlePlay = (e: any) => {
    e.stopPropagation();
    setPlay(true);
    if (vidIndex === 1) {
      loopPlayerRef.current?.play();
    } else {
      playerRef.current?.play();
    }
  };

  const handleVideoClick = (e: any) => {
    e.stopPropagation();
    // if video is playing, pause it
    if (play) {
      setPlay(false);
      if (vidIndex === 1) {
        loopPlayerRef.current?.pause();
      } else {
        playerRef.current?.pause();
      }
    }
  };

  useEffect(() => {
    if (vidIndex === 0 && loopPlayerRef.current) {
      loopPlayerRef.current.play();
    }
  }, [loopPlayerRef, vidIndex]);

  return (
    <div className="relative shadow-[0px_0px_18px_0px_#0000001A] border-[9px] border-[#FFFFFF33] rounded-lg">
      <video
        style={{ display: vidIndex === 1 ? "none" : "block" }}
        src={src}
        ref={playerRef}
        className="w-full h-full object-cover"
        controls={false}
        autoPlay={play}
        loop={true}
        playsInline={true}
        onClick={handleVideoClick}
        onEnded={() => setVidIndex((idx) => idx + 1)}
      />
      <video
        style={{ display: vidIndex === 0 ? "none" : "block" }}
        src={loopSrc}
        muted
        loop
        ref={loopPlayerRef}
      />
      {!play && (
        <button
          onClick={handlePlay}
          className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center"
        >
          <PlayButtonIcon />
        </button>
      )}
    </div>
  );
}

export default React.memo(VideoLoopView);
