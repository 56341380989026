import React, { useEffect, useState } from "react";
import { getUser } from "./utils";
import { api } from "./api";

export const AppContext = React.createContext({});

const isSubscribed = (paymentInfo) => {
  if (paymentInfo?.length > 0) {
    if (paymentInfo[0]?.status === "Paid") {
      return true;
    }
    // if status is Cancelled then check if createdAt is within 30 days of cancelledAt
    if (paymentInfo[0]?.status === "Cancelled") {
      const createdAt = new Date(paymentInfo[0]?.createdAt);
      const cancelledAt = new Date(paymentInfo[0]?.cancelledAt);
      const thirtyDays = 30 * 24 * 60 * 60 * 1000;
      if (cancelledAt.getTime() - createdAt.getTime() < thirtyDays) {
        return true;
      }
    }
  }
  return false;
};

export function AppProvider({ children }) {
  const [paymentInfo, setPaymentInfo] = React.useState<any[]>([]);

  // dashboard data
  const [dashboard, setDashboard] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const intervalRef = React.useRef<any>();

  const [isFetchingPaymentInfo, setIsFetchingPaymentInfo] =
    React.useState(false);
  const user = getUser();

  const fetchPaymentInfo = async (userId, showLoading) => {
    try {
      if (showLoading) {
        setIsFetchingPaymentInfo(true);
      }
      const subscriptionResponse = await api.getSubscription(userId);
      if (showLoading) {
        setIsFetchingPaymentInfo(false);
      }
      if (
        subscriptionResponse?.data?.length > 0 &&
        subscriptionResponse.data[0]?.status === "Paid"
      ) {
        setPaymentInfo(subscriptionResponse.data);
      }
    } catch (e) {
      setIsFetchingPaymentInfo(false);
    }
  };

  const refetchPaymentInfo = async (userId = user?.id) => {
    if (userId) {
      fetchPaymentInfo(userId, false);
    }
  };

  useEffect(() => {
    if (user?._id) {
      fetchPaymentInfo(user._id, true);
    }
  }, [user?._id]);

  const isSubscriptionActive = isSubscribed(paymentInfo);

  const fetchDashboardData = async (
    userId: string,
    shouldRefreshWholePage?: boolean
  ) => {
    shouldRefreshWholePage !== false && setLoading(true);
    try {
      const res = await api.getDashboard(userId);
      setDashboard(res);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const values = {
    paymentInfo,
    isSubscriptionActive,
    refetchPaymentInfo,
    setPaymentInfo,

    dashboard,
    setDashboard,
    loading,
    setLoading,
    fetchDashboardData,
  };

  if (isFetchingPaymentInfo) {
    return <div></div>;
  }
  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
}

export function useApp() {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error("useApp must be used within a AppProvider");
  }
  return context;
}
