import { ProgressBar } from "./components/ProgressBar";
import { ReactComponent as CloseIcon } from "../../assets/x.svg";
import { useEffect, useState } from "react";
import { Step1 } from "./components/Step1";
import { Step2 } from "./components/Step2";
import { Step3 } from "./components/Step3";
import { Step4 } from "./components/Step4";
import { Step5 } from "./components/Step5";
import { Step6 } from "./components/Step6";
import { Step7 } from "./components/Step7";
import { Step8 } from "./components/Step8";
import { Step9 } from "./components/Step9";
import { Step10 } from "./components/Step10";
import { api } from "../../services/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { clearTokenAndUser, getUser, setUser } from "../../services/utils";
import { ShowToast } from "../../services/toast";
import { pageView } from "../../services/mixpanel";
import { Step2BusinessConnect } from "./components/Step2BusinessConnect";

// const stepsObject = {
//   PROFILE: 1,
//   SOCIAL_MEDIA: 2,
//   BUSINESS_INFORMATION: 3,
//   TARGET_CLIENT: 4,
//   CORE_OFFERING: 5,
//   COACHING_JOURNEY: 6,
//   KEYWORDS: 7,
//   IMAGES: 8,
//   BRAND_VOICE: 9,
// };
const stepsObject = {
  PROFILE: 1,
  SOCIAL_MEDIA: 2,
  BUSINESS_INFORMATION: 3,
  TARGET_CLIENT: 4,
  CORE_OFFERING: 5,
  COACHING_JOURNEY: 6,
  KEYWORDS: 7,
  IMAGES: 8,
  // BRAND_VOICE: 9,
};

const steps: { [key: number]: any } = {
  1: Step1,
  2: Step2BusinessConnect,
  3: Step3,
  4: Step4,
  5: Step5,
  6: Step6,
  7: Step7,
  8: Step8,
  // 9: Step9,
};

const stepsCount = Object.keys(steps).length;

export function Onboarding({ onClose }: { onClose?: () => void }) {
  const [params] = useSearchParams();
  const stepParam = params.get("step");
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(1);
  const [data, setData] = useState<any>({});
  const [generatingPrompt, setGeneratingPrompt] = useState<boolean>(false);
  const [sectionData, setSectionData] = useState<any>({});
  const [user, setUserInfo] = useState<any>({});

  const handleBack = () => {
    if (step === 1) return;
    setStep(step - 1);
    navigate(`/onboarding?step=${step - 1}`);
  };

  const handleNext = (data?: any) => {
    // if (step === stepsCount) return;
    setStep(step + 1);
    if (step + 1 <= stepsCount) {
      setUser({
        ...user,
        pendingOnboardingSection: Object.keys(stepsObject).find(
          (key) => stepsObject[key] === step + 1
        ),
      });
    }

    if (data) {
      setData((prev) => ({ ...prev, ...data }));
    }
    if (step + 1 <= stepsCount) {
      navigate(`/onboarding?step=${step + 1}`);
      pageView();
    }
  };

  const handleClose = async () => {
    setStep(1);
    clearTokenAndUser();
    navigate("/login");
    onClose && onClose();
  };

  useEffect(() => {
    // on step 10 it should redirect to the dashboard after 3 seconds
    if (step === stepsCount + 1) {
      setUser({ ...user, isOnboardingCompleted: true });
      navigate("/dashboard");
      // setTimeout(() => {
      // }, 3000);
    }
  }, [step, navigate]);

  useEffect(() => {
    api.getOnboardingSections().then((res) => {
      setSectionData(res);
    });
  }, []);

  useEffect(() => {
    const user = getUser();
    setUserInfo(user);
  }, []);

  useEffect(() => {
    if (!user._id) return;
    api
      .getUserProfile(user._id)
      .then((res) => {
        setData(res.onboardingSubmissions);
      })
      .catch((err) => {
        ShowToast({
          type: "error",
          message: "Failed to fetch user data",
        });
      });
  }, [user._id]);

  useEffect(() => {
    if (!user._id) return;
    if (user.pendingOnboardingSection === false) {
      // navigate('/dashboard');
    } else {
      if (
        !stepParam ||
        (stepParam &&
          stepsObject[user.pendingOnboardingSection] < Number(stepParam))
      ) {
        setStep(stepsObject[user.pendingOnboardingSection] || 1);
        navigate(
          `/onboarding?step=${stepsObject[user.pendingOnboardingSection] || 1}`
        );
        pageView();
      } else {
        setStep(parseInt(stepParam));
      }
    }
    /** 
    api
      .getSubscription(user._id)
      .then((subscriptionResponse) => {
        if (
          subscriptionResponse?.data?.length > 0 &&
          subscriptionResponse.data[0]?.status === "Paid"
        ) {
          if (user.pendingOnboardingSection === false) {
            // navigate('/dashboard');
          } else {
            if (
              !stepParam ||
              (stepParam &&
                stepsObject[user.pendingOnboardingSection] < Number(stepParam))
            ) {
              setStep(stepsObject[user.pendingOnboardingSection] || 1);
              navigate(
                `/onboarding?step=${
                  stepsObject[user.pendingOnboardingSection] || 1
                }`
              );
              pageView();
            } else {
              setStep(parseInt(stepParam));
            }
          }
        } else {
          navigate("/subscription");
        }
      })
      .catch((err) => {
        ShowToast({
          type: "error",
          message: "Failed to fetch subscription data",
        });
      });
      */
  }, [user.pendingOnboardingSection, user._id]);

  useEffect(() => {
    pageView();
  }, []);

  const Step = steps[step];
  // if (step > stepsCount) {
  //   return (
  //     <div className="fixed top-0 bottom-0 right-0 left-0 h-[100vh] flex flex-col bg-black bg-opacity-40">
  //       {/* <button className="absolute right-10 top-5" onClick={handleClose}>
  //         <CloseIcon className="w-6 h-6" />
  //       </button> */}
  //       <div className="flex-1 bg-white flex flex-col pt-4 md:pt-8">
  //         <div className="flex-1 max-w-[665px] w-full bg-white mx-auto px-5 md:px-0 pb-5 pt-7 flex flex-col">
  //           <div className="flex-1 flex flex-col justify-center items-center">
  //             <Step10 name={data.FIRST_NAME} showLoading={generatingPrompt} />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }
  return sectionData[step - 1] ? (
    <div className="fixed top-0 bottom-0 right-0 left-0 h-[100vh] flex flex-col bg-black bg-opacity-40">
      {/* <button className="absolute right-10 top-5" onClick={handleClose}>
        <CloseIcon className="w-6 h-6" />
      </button> */}
      <div className="flex-1 bg-white pt-4 md:pt-8 flex flex-col">
        <div className="flex-1 max-w-[665px] w-full bg-white mx-auto px-5 md:px-0 pb-5 pt-7 flex flex-col">
          {!generatingPrompt && step <= stepsCount && (
            <ProgressBar total={stepsCount} progress={step} />
          )}
          <div className="flex-1 flex flex-col justify-center items-center">
            {step <= stepsCount ? (
              <Step
                onBack={handleBack}
                onNext={handleNext}
                data={data}
                metaData={sectionData[step - 1]}
                setGeneratingPrompt={setGeneratingPrompt}
                generatingPrompt={generatingPrompt}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
