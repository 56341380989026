import React, { useEffect, useMemo } from "react";
import { SelectInput } from "../../../components/SelectInput";
import { TextInput } from "../../../components/TextInput";
import { ActionButtons } from "./ActionButtons";
import { getOptionsFromQuestions } from "./Step3";
import { api } from "../../../services/api";
import { OtherDialog } from "./OtherDialog";
import { ReactComponent as CloseIcon } from "../../../assets/x.svg";
import { TextArea } from "../../../components/TextArea";

const hasOption = (questions: any[], value: string, field: string) => {
  const options = getOptionsFromQuestions(questions, field);
  return options.includes(value);
};

function CustomInput({
  handleClearOther,
  form,
  field,
}: {
  handleClearOther: (field: string) => void;
  form: any;
  field: string;
}) {
  const [toggle, setToggle] = React.useState<boolean>(false);

  return (
    <div className="relative">
      {toggle ? (
        <TextArea
          className="pr-10"
          value={form[field]}
          readOnly
          onClick={() => setToggle(false)}
        />
      ) : (
        <TextInput
          className="pr-10 text-ellipsis"
          value={form[field]}
          readOnly
          onClick={() => setToggle(true)}
        />
      )}
      <button
        className="absolute right-3 top-[2px] bottom-0"
        onClick={(e) => {
          e.stopPropagation();
          handleClearOther(field);
        }}
      >
        <CloseIcon className="[&>path]:stroke-black" />
      </button>
    </div>
  );
}
export function Step4({
  onBack,
  onNext,
  metaData,
  data,
  showBackButton = true,
  isEdit = false,
  id,
}: {
  onBack: () => void;
  onNext: (data: any) => void;
  metaData: any;
  showBackButton?: boolean;
  isEdit?: boolean;
  id?: string;
  data?: any;
}) {
  const [otherDialog, setOtherDialog] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<any>({});
  const handleBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    onBack();
  };
  const handleClearOther = (field) => {
    setForm((prev) => ({
      ...prev,
      [field]: undefined,
    }));
  };
  const handleNext = async (e: React.MouseEvent<HTMLButtonElement>) => {
    // check if all fields are filled
    // either ABOUT_CLIENT_PROFESSION or ABOUT_CLIENT_PROFESSION_OTHER
    // either STRUGGLE_WITH or STRUGGLE_WITH_OTHER
    // either HELP_CLIENT or HELP_CLIENT_OTHER
    if (
      !(
        form.IDEAL_CLIENT &&
        (form.ABOUT_CLIENT_PROFESSION || form.ABOUT_CLIENT_PROFESSION_OTHER) &&
        (form.STRUGGLE_WITH || form.STRUGGLE_WITH_OTHER) &&
        (form.HELP_CLIENT || form.HELP_CLIENT_OTHER)
      )
    ) {
      return;
    } else {
      setLoading(true);
      const response = await api.saveOnboardingSection(metaData.code, {
        answers: {
          ABOUT_CLIENT_PROFESSION: form.ABOUT_CLIENT_PROFESSION_OTHER
            ? form.ABOUT_CLIENT_PROFESSION_OTHER
            : form.ABOUT_CLIENT_PROFESSION,
          HELP_CLIENT: form.HELP_CLIENT_OTHER
            ? form.HELP_CLIENT_OTHER
            : form.HELP_CLIENT,
          IDEAL_CLIENT: form.IDEAL_CLIENT,
          STRUGGLE_WITH: form.STRUGGLE_WITH_OTHER
            ? form.STRUGGLE_WITH_OTHER
            : form.STRUGGLE_WITH,
        },
      });
      setLoading(false);
      if (response.status === 200) {
        onNext({
          IDEAL_CLIENT: form.IDEAL_CLIENT,
          ABOUT_CLIENT_PROFESSION: form.ABOUT_CLIENT_PROFESSION,
          ABOUT_CLIENT_PROFESSION_OTHER: form.ABOUT_CLIENT_PROFESSION_OTHER,
          HELP_CLIENT: form.HELP_CLIENT,
          HELP_CLIENT_OTHER: form.HELP_CLIENT_OTHER,
          STRUGGLE_WITH: form.STRUGGLE_WITH,
          STRUGGLE_WITH_OTHER: form.STRUGGLE_WITH_OTHER,
        });
      }
    }
    // onNext();
  };

  useEffect(() => {
    if (data) {
      setForm({
        ...data,
        ABOUT_CLIENT_PROFESSION_OTHER:
          !data.ABOUT_CLIENT_PROFESSION_OTHER &&
          !hasOption(
            metaData.questions,
            data.ABOUT_CLIENT_PROFESSION,
            "ABOUT_CLIENT_PROFESSION"
          )
            ? data.ABOUT_CLIENT_PROFESSION
            : data.ABOUT_CLIENT_PROFESSION_OTHER,
        STRUGGLE_WITH_OTHER:
          !data.STRUGGLE_WITH_OTHER &&
          !hasOption(metaData.questions, data.STRUGGLE_WITH, "STRUGGLE_WITH")
            ? data.STRUGGLE_WITH
            : data.STRUGGLE_WITH_OTHER,
        HELP_CLIENT_OTHER:
          !data.HELP_CLIENT_OTHER &&
          !hasOption(metaData.questions, data.HELP_CLIENT, "HELP_CLIENT")
            ? data.HELP_CLIENT
            : data.HELP_CLIENT_OTHER,
      });
    }
  }, [data]);

  const disabled = useMemo(() => {
    // check if all fields are filled
    // either ABOUT_CLIENT_PROFESSION or ABOUT_CLIENT_PROFESSION_OTHER
    // either STRUGGLE_WITH or STRUGGLE_WITH_OTHER
    // either HELP_CLIENT or HELP_CLIENT_OTHER
    const {
      IDEAL_CLIENT,
      ABOUT_CLIENT_PROFESSION,
      ABOUT_CLIENT_PROFESSION_OTHER,
      STRUGGLE_WITH,
      STRUGGLE_WITH_OTHER,
      HELP_CLIENT,
      HELP_CLIENT_OTHER,
    } = form;
    return !(
      IDEAL_CLIENT &&
      (ABOUT_CLIENT_PROFESSION || ABOUT_CLIENT_PROFESSION_OTHER) &&
      (STRUGGLE_WITH || STRUGGLE_WITH_OTHER) &&
      (HELP_CLIENT || HELP_CLIENT_OTHER)
    );
  }, [form]);

  return (
    <div className="w-full overflow-auto max-h-[calc(100vh-125px)] py-2">
      <h1 className="text-dark1 text-lg  md:text-2xl font-semibold leading-normal md:leading-loose">
        Let’s explore your ideal clientele
      </h1>
      <p className="text-dark2 text-sm leading-normal mb-5 mt-1.5">
        Specializing in a niche sharpens expertise and attracts ideal clients.
        Paint a picture of your ideal client's attributes, challenges, and
        aspirations.
      </p>
      <div className="flex-1 flex gap-4 flex-col">
        <SelectInput
          data={getOptionsFromQuestions(metaData.questions, "IDEAL_CLIENT")}
          onChange={(e) =>
            setForm((prev) => ({ ...prev, IDEAL_CLIENT: e.target.value }))
          }
          value={form.IDEAL_CLIENT || ""}
          placeholder="My ideal clients are …"
          disabled={loading}
          className="!bg-[99%_55%]"
        />
        {form.ABOUT_CLIENT_PROFESSION_OTHER ? (
          <CustomInput
            form={form}
            field="ABOUT_CLIENT_PROFESSION_OTHER"
            handleClearOther={handleClearOther}
          />
        ) : (
          <SelectInput
            className="!bg-[99%_55%]"
            data={getOptionsFromQuestions(
              metaData.questions,
              "ABOUT_CLIENT_PROFESSION"
            )}
            onChange={(e) => {
              if (e.target.value === "Other") {
                setOtherDialog({
                  name: "ABOUT_CLIENT_PROFESSION_OTHER",
                  placeholder: "Who are…",
                });
                setForm((prev) => ({
                  ...prev,
                  ABOUT_CLIENT_PROFESSION: undefined,
                }));
              } else {
                setForm((prev) => ({
                  ...prev,
                  ABOUT_CLIENT_PROFESSION: e.target.value,
                }));
              }
            }}
            value={form.ABOUT_CLIENT_PROFESSION || ""}
            placeholder="Who are…"
            disabled={loading}
          />
        )}
        {/** readonly input for other option  */}
        {form.STRUGGLE_WITH_OTHER ? (
          <CustomInput
            form={form}
            field="STRUGGLE_WITH_OTHER"
            handleClearOther={handleClearOther}
          />
        ) : (
          <SelectInput
            className="!bg-[99%_55%]"
            placeholder="Struggling with…"
            data={getOptionsFromQuestions(metaData.questions, "STRUGGLE_WITH")}
            onChange={(e) => {
              if (e.target.value === "Other") {
                setOtherDialog({
                  name: "STRUGGLE_WITH_OTHER",
                  placeholder: "Struggling with…",
                });
                setForm((prev) => ({ ...prev, STRUGGLE_WITH: undefined }));
              } else {
                setForm((prev) => ({ ...prev, STRUGGLE_WITH: e.target.value }));
              }
            }}
            value={form.STRUGGLE_WITH || ""}
            disabled={loading}
          />
        )}
        {form.HELP_CLIENT_OTHER ? (
          <CustomInput
            form={form}
            field="HELP_CLIENT_OTHER"
            handleClearOther={handleClearOther}
          />
        ) : (
          <SelectInput
            className="!bg-[99%_55%]"
            placeholder="I help them by…"
            data={getOptionsFromQuestions(metaData.questions, "HELP_CLIENT")}
            onChange={(e) => {
              if (e.target.value === "Other") {
                setOtherDialog(true);
                setForm((prev) => ({ ...prev, HELP_CLIENT: undefined }));
              } else {
                setForm((prev) => ({ ...prev, HELP_CLIENT: e.target.value }));
              }
            }}
            value={form.HELP_CLIENT || ""}
            disabled={loading}
          />
        )}
      </div>
      <div className="my-8 border-t border-lightGray"></div>
      <ActionButtons
        onBack={showBackButton ? handleBack : undefined}
        onNext={handleNext}
        disableNext={disabled}
        loading={loading}
      />
      {otherDialog && (
        <OtherDialog
          isOpen={!!otherDialog}
          onClose={() => setOtherDialog(null)}
          value={form}
          onChange={(values: any) =>
            setForm((prev: any) => ({ ...prev, ...values }))
          }
        />
      )}
    </div>
  );
}
