import { ReactComponent as CloseIcon } from "../../../assets/x.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Step4 } from "../../onboarding/components/Step4";
import { Step5 } from "../../onboarding/components/Step5";
import { Step6 } from "../../onboarding/components/Step6";
import { Step7 } from "../../onboarding/components/Step7";
import { getUser, setUser } from "../../../services/utils";
import { api } from "../../../services/api";
import { ProgressBar } from "../../onboarding/components/ProgressBar";
import { Step10 } from "../../onboarding/components/Step10";
import React from "react";
import { Step9 } from "../../onboarding/components/Step9";

const stepsObject = {
  TARGET_CLIENT: 4,
  CORE_OFFERING: 5,
  COACHING_JOURNEY: 6,
  KEYWORDS: 7,
};

const stepsWithInstagram: { [key: string]: any }[] = [
  { id: 4, Component: Step4 },
  { id: 5, Component: Step5 },
  { id: 6, Component: Step6 },
  { id: 7, Component: Step9 },
];

const stepsWithoutInstagram: { [key: string]: any }[] = [
  { id: 4, Component: Step4 },
  { id: 5, Component: Step5 },
  { id: 6, Component: Step6 },
];

export function EditOnboarding({
  onClose,
  sectionData,
  onboardingSubmissions,
  instagramConnected,
}: {
  onClose?: (flag?: boolean) => void;
  sectionData?: any;
  onboardingSubmissions?: any;
  instagramConnected?: boolean;
}) {
  const navigate = useNavigate();
  const controllerRef = React.useRef(new AbortController());
  const [step, setStep] = useState<number>(1);
  const [data, setData] = useState<any>({});
  const user = getUser();
  const steps = instagramConnected ? stepsWithInstagram : stepsWithoutInstagram;
  const stepsCount = steps.length;
  const handleBack = () => {
    if (step === 1) return;
    setStep(step - 1);
  };

  const handleNext = (data?: any) => {
    // if (step === stepsCount) return;
    setStep(step + 1);
    if (data) {
      setData((prev) => ({ ...prev, ...data }));
    }
  };

  const handleClose = async () => {
    setStep(1);
    navigate("/profile");
    onClose && onClose();
    controllerRef.current && controllerRef.current.abort();
  };

  useEffect(() => {
    // on step 10 it should redirect to the dashboard after 3 seconds
    if (step === stepsCount + 1) {
      setUser({ ...user, isOnboardingCompleted: true });
      setTimeout(() => {
        onClose && onClose(true);
      }, 3000);
    }
  }, [step, navigate]);

  useEffect(() => {
    if (onboardingSubmissions) {
      setData(onboardingSubmissions);
    }
  }, [onboardingSubmissions]);

  const Step = steps[step - 1]?.Component;
  return (
    <div className="fixed top-0 bottom-0 right-0 left-0 h-[100vh] flex flex-col bg-black bg-opacity-40">
      <button className="absolute right-10 top-5" onClick={handleClose}>
        <CloseIcon className="w-6 h-6 [&_path]:stroke-black" />
      </button>
      <div className="flex-1 bg-white flex flex-col pt-4 md:pt-8">
        <div className="flex-1 max-w-[665px] w-full bg-white mx-auto px-5 md:px-0 pb-5 pt-7 flex flex-col">
          {step <= stepsCount && (
            <ProgressBar total={stepsCount} progress={step} />
          )}
          {sectionData[step - 1] && (
            <div className="flex-1 flex flex-col justify-center items-center">
              {step <= stepsCount ? (
                <Step
                  onBack={handleBack}
                  onNext={handleNext}
                  data={data}
                  metaData={sectionData[steps[step - 1].id - 1]}
                  showBackButton={step !== 1}
                  generatePrompt={step === stepsCount}
                  id={user._id}
                  controllerRef={controllerRef}
                />
              ) : (
                <Step10 />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
