import { Button } from "../../../components/Button";
import { Modal } from "../../../components/Modal";

export function RegenerateConfirm({
  open,
  onClose,
  onConfirm,
  loading,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading: boolean;
}) {
  return (
    <Modal
      contentClassName="max-w-[400px]"
      open={open}
      onClose={!loading ? onClose : () => {}}
      showCloseButton={false}
      modalClassName="!overflow-y-hidden !md:overflow-y-auto"
    >
      <div className="text-center">
        <div className="text-center text-[#000] text-2xl font-semibold leading-[34px]">
          Are you sure you want to regenerate your visuals?
        </div>
        <div className="mt-3.5 leading-[18px] text-center text-black text-sm font-normal">
          This will generate a new image for all of your scheduled posts. This
          can not be undone.
        </div>
        <div className="flex gap-2.5 justify-center mt-6">
          <Button variant="outline" disabled={loading} onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            loading={loading}
            disabled={loading}
            variant="primary"
          >
            Yes, Update
          </Button>
        </div>
      </div>
    </Modal>
  );
}
