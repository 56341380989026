import React from "react";
import { Routes, Route } from "react-router-dom";
import { Onboarding } from "./onboarding";
import { EditProfile } from "./profile";
import { Main } from "./main";
import { Login, Signup } from "./auth";
import { ConfirmEmail } from "./auth/ConfirmEmail";
import { ForgotPassword } from "./auth/ForgotPassword";
import { ResetPassword } from "./auth/ResetPassword";
import { PlanSelection } from "./payment/PlanSelection";
import { Schedule } from "./schedule";
import { ProfileWrapper } from "./profile/ProfileWrapper";
// import { MediaLibrary } from "./media-library/MediaLibrary";
import { Progress } from "./progress/Progress";
import { Dashboard } from "./dashboard/Dashboard";
import { AyrshareCallback } from "./Ayrshare/AyrshareCallback";
import { StripeCallback } from "./payment/StripeCallback";
import { SettingsPage } from "./settings/SettingsPage";

const RoutesList = () => {
  return (
    <Routes>
      <Route path="/onboarding" element={<Onboarding />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Signup />} />
      <Route path="/confirm-email" element={<ConfirmEmail />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      {/* <Route path="/payment" element={<Payment />} /> */}
      <Route path="/ayrshare" element={<AyrshareCallback />} />
      <Route path="/stripe" element={<StripeCallback />} />
      <Route path="/subscription" element={<PlanSelection />} />
      <Route path="/" element={<Main />}>
        <Route index path="dashboard" element={<Dashboard />} />
        <Route path="profile" element={<ProfileWrapper />} />
        <Route path="profile/edit" element={<EditProfile />} />
        <Route path="schedule" element={<Schedule />} />
        {/* <Route path="media-library" element={<MediaLibrary />} /> */}
        <Route path="progress" element={<Progress />} />
        <Route path="settings" element={<SettingsPage />} />
      </Route>
    </Routes>
  );
};
export default RoutesList;
