import React, { useEffect } from "react";
import { ActionButtons } from "./ActionButtons";
import { api } from "../../../services/api";

export const keywordList = [
  "Energetic",
  "Uplifting",
  "Simple",
  "Serene",
  "Intuitive",
  "Dynamic",
  "Radiant",
  "Grounded",
  "Inspiring",
  "Passionate",
  "Wholesome",
  "Nurturing",
  "Bold",
  "Visionary",
  "Empathetic",
  "Joyful",
  "Refreshing",
  "Balanced",
  "Harmonious",
  "Sexy",
  "Magnetic",
  "Luminous",
  "Deep",
  "Evocative",
  "Empowering",
  "Tranquil",
  "Spirited",
  "Motivational",
];

export function Step7({
  onBack,
  onNext,
  data,
  metaData,
  generatePrompt,
  id,
}: {
  onBack: () => void;
  onNext: (data: any) => void;
  data?: any;
  metaData: any;
  generatePrompt?: boolean;
  id?: string;
}) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<string[]>([]);
  const handleBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    onBack();
  };
  const handleNext = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (selected.length > 0) {
      setLoading(true);
      const response = await api.saveOnboardingSection(metaData.code, {
        answers: {
          KEYWORDS: selected,
        },
      });
      setLoading(false);
      if (response.status === 200) {
        if (generatePrompt && id) {
          setLoading(true);
          const response = await api.generatePrompt(id, {
            promptType: "EDIT_PROFILE",
          });
          setLoading(false);
        }
        onNext({
          KEYWORDS: selected,
        });
      }
    }
  };
  const toggleSelected = (item: string) => {
    if (selected.includes(item)) {
      setSelected(selected.filter((i) => i !== item));
    } else {
      if (selected.length < 5) {
        setSelected([...selected, item]);
      }
    }
  };

  useEffect(() => {
    if (data) {
      setSelected(data.KEYWORDS || []);
    }
  }, [data]);

  const disabled = selected.length < 1;
  return (
    <div className="w-full overflow-auto max-h-[calc(100vh-125px)] pt-2 pb-16 md:py-2">
      <h1 className="text-dark1 text-lg  md:text-2xl font-semibold leading-normal md:leading-loose">
        Your brand is an extension of your essence
      </h1>
      <p className="text-dark2 text-sm leading-normal mb-5 mt-1.5">
        To help us crystallize your brand identity, please select up to 5 words
        that resonate with your coaching spirit:
      </p>
      <div className="flex-1 md:p-5 grid grid-cols-[repeat(auto-fill,minmax(120px,1fr))] md:grid-cols-5 gap-2 md:bg-ultraLightGray">
        {keywordList.map((item, i) => (
          <button
            onClick={() => toggleSelected(item)}
            className={`w-28 text-center px-3.5 py-2 bg-white rounded border ${
              selected.includes(item) ? "border-primary" : "border-lightGray"
            }`}
            disabled={loading}
            key={`${item}-${i}`}
          >
            <div
              className={`${
                selected.includes(item) ? "text-black" : "text-grey1"
              } text-sm font-medium leading-normal`}
            >
              {item}
            </div>
          </button>
        ))}
      </div>
      <div className="my-8 border-t border-lightGray"></div>
      <ActionButtons
        onBack={handleBack}
        onNext={handleNext}
        disableNext={disabled}
        loading={loading}
      />
    </div>
  );
}
